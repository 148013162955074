import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import Swal from "sweetalert2"
import Select from "react-select"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  FormFeedback,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useNavigate, useParams } from "react-router-dom"
import {
  addVendorContracts,
  getGoodsServiceList,
} from "helpers/fakebackend_helper"

const AddVendorContracts = () => {
  const navigate = useNavigate()
  const [selectedFiles, setSelectedFiles] = useState([])

  const [goodServices, setGoodServices] = useState([])
  useEffect(() => {
    const goodServicesList = async () => {
      try {
        const response = await getGoodsServiceList()
        setGoodServices(response.data)
      } catch (error) {
        console.error("Error fetching project compliance list", error)
      }
    }

    goodServicesList()
  }, [])

  const { id } = useParams()

  const validation = useFormik({
    initialValues: {
      vendor_id: "",
      purpose: "",
      validity_from: "",
      validity_to: "",
      contract_documents: [],
      services: [], // Change to array of service objects
    },
    validationSchema: Yup.object({
      purpose: Yup.string().required("Purpose is required"),
      validity_from: Yup.date().required("Validity from is required"),
      validity_to: Yup.date()
        .required("Validity to is required")
        .min(
          Yup.ref("validity_from"),
          "Validity to must be later than validity from"
        ),

      // services: Yup.array().min(1, "Please select at least one service"), // Change to array validation
    }),
    onSubmit: async values => {
      try {
        const response = await addVendorContracts(values)

        if (response && response.status === true) {
          Swal.fire({
            title: "Success!",
            text: "Vendor contract added successfully",
            icon: "success",
            confirmButtonText: "OK",
          }).then(result => {
            if (result.isConfirmed) {
              // Redirect to the new page (e.g., /contracts or any page you want)
              navigate(`/vendor/view-vendor/${id}`) // Replace with your desired URL
            }
          })
        } else {
          Swal.fire({
            title: "Error!",
            text: "Failed to add vendor contract. Please try again.",
            icon: "error",
            confirmButtonText: "OK",
          })
        }
      } catch (error) {
        console.error("Error adding vendor contract:", error)
        Swal.fire({
          title: "Error!",
          text: "An error occurred while adding the contract. Please try again.",
          icon: "error",
          confirmButtonText: "OK",
        })
      }
    },
  })

  useEffect(() => {
    if (id) {
      validation.setFieldValue("vendor_id", id)
    }
  }, [id])

  console.log(validation)
  const handleFileChange = event => {
    const files = Array.from(event.target.files) // Convert FileList to array
    setSelectedFiles(files) // Update local state for file display
    validation.setFieldValue("contract_documents", files[0]) // Update Formik's field value
  }

  return (
    <div className="page-content">
      <Container fluid={true}>
        <CardTitle className="h4" style={{ fontSize: "20px" }}>
          <div className="d-flex gap-2 mb-3">
            <i
              className="mdi mdi-arrow-left font-size-20"
              style={{ color: "grey" }}
              onClick={() => {
                navigate(-1)
              }}
            ></i>
            Add Vendor Contract
          </div>
        </CardTitle>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Form
                  autoComplete="off"
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                >
                  <Card>
                    <CardBody>
                      <CardTitle>Vendor</CardTitle>
                      <Row>
                        <Col>
                          <div className="mb-3">
                            <Label for="purpose">Purpose</Label>
                            <Input
                              type="text"
                              name="purpose"
                              className="form-control"
                              id="purpose"
                              placeholder="Enter Vendor Contract Purpose"
                              onChange={validation.handleChange}
                              value={validation.values.purpose}
                              invalid={
                                validation.touched.purpose &&
                                validation.errors.purpose
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.purpose &&
                            validation.errors.purpose ? (
                              <FormFeedback type="invalid">
                                {validation.errors.purpose}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col>
                          <div className="mb-3">
                            <Label for="validity_from">Validity From</Label>
                            <Input
                              type="date"
                              name="validity_from"
                              className="form-control"
                              id="validity_from"
                              placeholder="Enter Validity From"
                              onChange={validation.handleChange}
                              value={validation.values.validity_from}
                              invalid={
                                validation.touched.validity_from &&
                                validation.errors.validity_from
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.validity_from &&
                            validation.errors.validity_from ? (
                              <FormFeedback type="invalid">
                                {validation.errors.validity_from}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col>
                          <div className="mb-3">
                            <Label for="validity_to">Validity To</Label>
                            <Input
                              type="date"
                              name="validity_to"
                              className="form-control"
                              id="validity_to"
                              placeholder="Enter Validity To"
                              onChange={validation.handleChange}
                              value={validation.values.validity_to}
                              invalid={
                                validation.touched.validity_to &&
                                validation.errors.validity_to
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.validity_to &&
                            validation.errors.validity_to ? (
                              <FormFeedback type="invalid">
                                {validation.errors.validity_to}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="mb-3">
                            <Label htmlFor="contract_documents">
                              Contract Documents
                            </Label>
                            <Input
                              type="file"
                              name="contract_documents"
                              className="form-control"
                              id="contract_documents"
                              placeholder="Choose Documents"
                              onChange={handleFileChange} // Update the change handler to support file input
                              onBlur={validation.handleBlur}
                              multiple // Allow multiple files
                              invalid={
                                validation.touched.contract_documents &&
                                validation.errors.contract_documents
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.contract_documents &&
                              validation.errors.contract_documents && (
                                <FormFeedback type="invalid">
                                  {validation.errors.contract_documents}
                                </FormFeedback>
                              )}
                            {selectedFiles.length > 0 && (
                              <div className="mt-3">
                                <h6>Selected Documents:</h6>
                                <ul>
                                  {selectedFiles.map((file, index) => (
                                    <li key={index}>
                                      <span>{file.name}</span>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label className="control-label">
                              Select Services
                            </Label>
                            <Select
                              placeholder="Please Select Services"
                              options={goodServices.map(service => ({
                                value: service.id,
                                label: service.proposal_type,
                              }))}
                              isMulti={true}
                              id="services"
                              name="services"
                              value={validation.values.services
                                .map(serviceId => {
                                  const selectedService = goodServices.find(
                                    service =>
                                      Number(service.id) === Number(serviceId.service_id)
                                  )
                                 
                                  return selectedService
                                    ? {
                                        value: selectedService.id,
                                        label: selectedService.proposal_type,
                                      }
                                    : null
                                })
                                .filter(service => service !== null)} // Filter out any null values
                              onChange={selectedOptions => {
                                const selectedValues = selectedOptions
                                  ? selectedOptions.map(option => ({
                                      service_id: option.value,
                                    })) // Set each service as an object with `service_id`
                                  : []
                                validation.setFieldValue(
                                  "services",
                                  selectedValues
                                ) // Store it as an array of objects with `service_id`
                              }}
                              onBlur={validation.handleBlur}
                              className={`${
                                validation.touched.services &&
                                validation.errors.services
                                  ? " is-invalid"
                                  : ""
                              }`}
                            />

                            {validation.touched.services &&
                              validation.errors.services && (
                                <FormFeedback type="invalid">
                                  {validation.errors.services}
                                </FormFeedback>
                              )}
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>

                  <button className="btn btn-primary " type="submit">
                    Add Contract
                  </button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default AddVendorContracts
