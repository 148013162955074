import axios from "axios"
import {
  del,
  downloadExcel,
  downloadPDF,
  get,
  post,
  postWithFile,
  put,
} from "./api_helper"
import * as url from "./url_helper"

// Login Methods
const login = data => post(url.LOGIN, data)
//LOGOUT
const logout = data => get(url.LOGOUT_USER, data)
// Update Password
export const updatePass = user => post(url.UPDATE_PASS, user)
// Forgot Password
export const forgotPassword = user => post(url.FORGET_PASSWORD, user)

//Dashboard

export const getCountForDashboard = () => get(url.GET_COUNT_FOR_DASHBOARD)
export const getVacantProjectUnitList = () =>
  get(url.GET_VACANT_PROJECT_UNIT_LIST)
export const ActivityFilter = activity => post(url.ACTIVITY_FILTER, activity)
export const newCustomerListAPI = newCustomerList =>
  post(url.NEW_CUSTOMER_LIST, newCustomerList)
//Excell
export const newCustomerListAPIExcel = data =>
  downloadExcel(url.NEW_CUSTOMER_LIST_EXCEL, data)
export const downloadProjectUnitDataExcel = data =>
  downloadExcel(url.PROJECT_UNIT_DATA_EXCEL, data)
export const downloadBillingDataExcel = data =>
  downloadExcel(url.BILLING_DATA_EXCEL, data)
export const downloadBillPaymentExcel = data =>
  downloadExcel(url.BILL_PAYMENT_EXCEL, data)
//
export const customerOutstandingListExcel = data =>
  downloadExcel(url.CUSTOMER_OUTSTANDING_LIST_EXCEL, data)
export const vendorOutstandingListExcel = data =>
  downloadExcel(url.VENDOR_OUTSTANDING_LIST_EXCEL, data)
export const newVendorDataExcel = data =>
  downloadExcel(url.NEW_VENDOR_DATA_EXCEL, data)
export const vendorAssignedticketExcel = data =>
  downloadExcel(url.VENDOR_ASSIGNED_TICKET_EXCEL, data)
export const vendorInvoiceListExcel = data =>
  downloadExcel(url.VENDOR_INVOICE_LIST_EXCEL, data)
export const vendorPaymentListExcel = data =>
  downloadExcel(url.VENDOR_PAYMENT_LIST_EXCEL, data)
export const statusWiseTicketListExcel = data =>
  downloadExcel(url.STATUS_WISE_TICKET_LIST_EXCEL, data)

//////dashboard modal
export const newUnitAssignedList = newUnitList =>
  post(url.NEW_UNIT_ASSIGNED_LIST, newUnitList)
export const billAmountList = billAmountLIst =>
  post(url.BILL_AMOUNT_LIST, billAmountLIst)
export const paymentAmountList = paymentList =>
  post(url.PAYMENT_AMOUNT_LIST, paymentList)
export const newCustomerGraph = customerGraph =>
  post(url.NEW_CUSTOMER_GRAPH, customerGraph)
export const newUnitAssignedGraph = unitAssignedGraph =>
  post(url.NEW_UNITS_ASSIGNED_GRAPH, unitAssignedGraph)
export const billAndPaymentGraph = billPaymentGraph =>
  post(url.BILL_AND_PAYMENT_GRAPH, billPaymentGraph)
//activity
export const customerOutstandingList = data =>
  post(url.CUSTOMER_OUTSTANTDING_LIST, data)
export const newVendorList = data => post(url.NEW_VENDOR_LIST, data)
export const vendorOutstandingList = data =>
  post(url.VENDOR_OUTSTANDING_LIST, data)
export const vendorPaymentList = data => post(url.VENDOR_PAYMENT_LIST, data)
export const vendorInvoiceList = data => post(url.VENDOR_INVOICE_LIST, data)
export const vendorTicketList = data => post(url.VENDOR_TICKET_LIST, data)
export const statusWiseTicketList = data =>
  post(url.STATUS_WISE_TICKET_LIST, data)
// MY PROFILE
export const getProfileDetail = id => get(`${url.GET_PROFILE_DETAIL}/${id}`, id)

//FORM 1 TIME REGION
export const addNewTimeregion = timeregion =>
  post(url.ADD_NEW_TIMEREGION, timeregion)
export const getTimeregion = () => get(url.GET_TIMEREGION)

//FORM 2 FILE CONFIGURATION

export const addNewFileConfiguration = fileconfiguration =>
  post(url.ADD_NEW_FILECONFIGURATION, fileconfiguration)
export const getFileConfiguration = () => get(url.GET_FILECONFIGURATION)

//FORM 3 RECAPTCHA CONFIGURATION

export const addNewRecaptchaConfiguration = recaptchaconfiguration =>
  post(url.ADD_NEW_RECAPTCHACONFIGURATION, recaptchaconfiguration)
export const getRecaptchaConfiguration = () =>
  get(url.GET_RECAPTCHACONFIGURATION)

//FORM 4 EMAIL CONFIGURATION

export const addNewEmailConfiguration = emailconfiguration =>
  post(url.ADD_NEW_EMAILCONFIGURATION, emailconfiguration)
export const getEmailConfiguration = () => get(url.GET_EMAILCONFIGURATION)

//FORM 5 USER PASSWORD SETTINGS

export const addNewUserPasswordSettings = userpasswordsetting =>
  post(url.ADD_NEW_USERPASSWORDSETTINGS, userpasswordsetting)
export const getUserPasswordSettings = () => get(url.GET_USERPASSWORDSETTINGS)

//DATE AND TIME FORMAT

export const getDateTimeFormat = () => get(url.GET_DATETIMEFORMAT)

//TIME ZONE

export const getTimeZone = () => get(url.GET_TIMEZONE)

//CURRENCY

export const getCurrency = () => get(url.GET_CURRENCY)

// -------------------------------------------------//----------------------------------------------------------//----------
// get company
export const getCompanies = () => get(url.GET_COMPANIES)
// add company
export const addNewCompany = company =>
  postWithFile(url.ADD_NEW_COMPANY, company)
// update company
export const updateCompany = (id, company) =>
  postWithFile(`${url.UPDATE_COMPANY}/${id}`, company)
// export const updateCompany = (id ,company) => put(`${url.UPDATE_COMPANY}/${id}`, company);
// delete company
export const deleteCompany = company =>
  del(`${url.DELETE_COMPANY}/${company}`, { headers: { company } })
// get company details
export const getCompanyDetails = id =>
  get(`${url.GET_COMPANY_DETAIL}/${id}`, id)
// get(`${url.GET_COMPANY_DETAIL.replace(":id", id)}`);

export const getCompanyDocuments = id =>
  get(`${url.GET_DOCUMENT_LIST_COMPANY}/${id}`, id)
export const addCompanyDocument = document =>
  postWithFile(url.ADD_DOCUMENT_COMPANY, document)
export const deleteCompanyDocument = document =>
  del(`${url.DELETE_DOCUMENT_COMPANY}/${document}`, { headers: { document } })

export const getCompanyLocationList = id =>
  get(`${url.GET_COMPANY_LOCATION_LIST}/${id}`, id)
export const getCompanyLocationDetail = id =>
  get(`${url.GET_COMPANY_LOCATION_DETAIL}/${id}`, id)
export const addCompanyLocation = document =>
  postWithFile(url.ADD_COMPANY_LOCATION, document)
export const updateCompanyLocation = (id, gallery) =>
  post(`${url.UPDATE_COMPANY_LOCATION}/${id}`, gallery)
export const deleteCompanyLocation = location =>
  del(`${url.DELETE_COMPANY_LOCATION}/${location}`, { headers: { location } })
// -------------------------------------------------//----------------------------------------------------------//-------------------------------------
// get project
export const getProjects = id => get(`${url.GET_PROJECTS}/${id}`, id)
export const getProjectName = id => get(`${url.GET_PROJECT_NAME}/${id}`, id)
// add project
export const addNewProject = project =>
  postWithFile(url.ADD_NEW_PROJECT, project)
export const addNewProjectName = project =>
  post(url.ADD_NEW_PROJECT_NAME, project)
export const addNewProjectUnit = project =>
  post(url.ADD_NEW_PROJECT_UNIT, project)
// update project
export const updateProject = (id, project) =>
  postWithFile(`${url.UPDATE_PROJECT}/${id}`, project)
// export const updateProject = (id ,project) => put(`${url.UPDATE_PROJECT}/${id}`, project);
// delete project
export const deleteProject = project =>
  del(`${url.DELETE_PROJECT}/${project}`, { headers: { project } })
// get project details
export const getProjectDetails = id =>
  get(`${url.GET_PROJECT_DETAIL}/${id}`, id)
// get(`${url.GET_PROJECT_DETAIL.replace(":id", id)}`);
export const getProjectUnitDetail = id =>
  get(`${url.GET_PROJECT_UNIT_DETAIL}/${id}`, id)

//GET PROJECT MANAGER
export const getProjectManager = () => get(url.GET_PROJECT_MANAGER)

export const getCompanyForProject = () => get(url.GET_COMPANY_FOR_PROJECT)
export const getCompanyDetailForProject = id =>
  get(`${url.GET_COMPANY_DETAIL_FOR_PROJECT}/${id}`, id)
export const getCompanyLocationForProject = id =>
  get(`${url.GET_COMPANY_LOCATION_FOR_PROJECT}/${id}`, id)
export const getCompanyLocationDetailForProject = id =>
  get(`${url.GET_COMPANY_LOCATION_DETAIL_FOR_PROJECT}/${id}`, id)

// GALLERY

export const getVideo = userId =>
  get(`${url.GET_VIDEO}/${userId}`, { headers: { userId } })
export const getVideoDetail = id => get(`${url.GET_VENDOR_DETAIL}/${id}`, id)
export const addVideo = video => post(url.ADD_VIDEO, video)
export const deleteVideo = video =>
  del(`${url.DELETE_VIDEO}/${video}`, { headers: { video } })

// VIDEO

export const getGallery = gallery =>
  get(`${url.GET_GALLERY}/${gallery}`, { headers: { gallery } })
export const addGallery = gallery => postWithFile(url.ADD_GALLERY, gallery)
export const updateGallery = (id, gallery) =>
  post(`${url.UPDATE_GALLERY}/${id}`, gallery)
export const deleteGallery = gallery =>
  del(`${url.DELETE_GALLERY}/${gallery}`, { headers: { gallery } })

//PROJECT UNIT

export const getProjectUnit = project =>
  get(`${url.GET_PROJECT_UNIT}/${project}`, { headers: { project } })
export const updateProjectUnit = (id, project) =>
  post(`${url.UPDATE_PROJECT_UNIT}/${id}`, project)
export const deleteProjectUnit = project =>
  del(`${url.DELETE_PROJECT_UNIT}/${project}`, { headers: { project } })
export const getFacilitiesAvailable = () => get(url.GET_FACILITIES_AVAILABLE)

// -----------------------------------------------//---------------------------------------------------------//-------------------------------------------------

// get user
export const getUsers = () => get(url.GET_USERS)
// add user
export const addNewUser = user => postWithFile(url.ADD_NEW_USER, user)
// update user
export const updateUser = (id, user) =>
  postWithFile(`${url.UPDATE_USER}/${id}`, user)
// export const updateUser = (id ,user) => post(`${url.UPDATE_USER}/${id}`, user);
// export const updateUser = (id ,user) => put(`${url.UPDATE_COMPANY}/${id}`, user);
// delete user
export const deleteUser = user =>
  del(`${url.DELETE_USER}/${user}`, { headers: { user } })
// get user details
export const getUserDetails = id => get(`${url.GET_USER_DETAIL}/${id}`, id)
// get(`${url.GET_USER_DETAIL.replace(":id", id)}`);

export const getDocumentation = doc =>
  get(`${url.GET_DOCUMENTATION}/${doc}`, { headers: { doc } })
export const addDocumentation = doc => postWithFile(url.ADD_DOCUMENTATION, doc)
export const updateDocumentation = (id, doc) =>
  post(`${url.UPDATE_DOCUMENTATION}/${id}`, doc)
export const deleteDocumentation = doc =>
  del(`${url.DELETE_DOCUMENTATION}/${doc}`, { headers: { doc } })

export const getPastEmployment = emp =>
  get(`${url.GET_PAST_EMPLOYMENT}/${emp}`, { headers: { emp } })
export const getPastEmploymentDetail = emp =>
  get(`${url.GET_PAST_EMPLOYMENT_DETAIL}/${emp}`, { headers: { emp } })
export const addPastEmployment = (id, emp) =>
  post(`${url.ADD_PAST_EMPLOYMENT}/${id}`, emp)
export const updatePastEmployment = (id, emp) =>
  post(`${url.UPDATE_PAST_EMPLOYMENT}/${id}`, emp)
export const deletePastEmployment = emp =>
  del(`${url.DELETE_PAST_EMPLOYMENT}/${emp}`, { headers: { emp } })

export const getAssignedCustomer = () => get(url.GET_ASSIGNED_CUSTOMER)

export const resetUserPassword = (id, values) =>
  post(`${url.RESET_USER_PASSWORD}/${id}`, values)

export const getEmployeeCode = () => get(url.GET_EMPLOYEE_CODE)
export const getProjectNamesForUser = () => get(url.GET_PROJECT_NAME_FOR_USER)

//REINVITE USER

export const reinvite = reinvite =>
  post(`${url.REINVITE}/${reinvite}`, reinvite)

// User Permission

export const getUserPermission = user =>
  get(`${url.GET_USER_PERMISSION}/${user}`, { headers: { user } })

export const addNewUserRole = userRole =>
  post(url.ADD_USER_PERMISSION, userRole)

// -----------------------------------------------//---------------------------------------------------------//-------------------------------------------------

// get role
export const getRoles = () => get(url.GET_ROLES)
export const getUsersforRole = (data) => post(url.USER_FOR_ROLE,data)
// get Modules
export const getModules = id => get(`${url.GET_MODULES}/${id}`)
// get Maange permission
export const getManagePermission = id =>
  get(`${url.GET_MANAGE_PERMISSION}/${id}`)
// add role
export const addNewRole = role => post(url.ADD_NEW_ROLE, role)
// update role
export const updateRole = (id, role) => post(`${url.UPDATE_ROLE}/${id}`, role)
// export const updateRole = (id ,role) => put(`${url.UPDATE_ROLE}/${id}`, role);
export const updateRoleComponentAccess = role =>
  post(`${url.ROLE_COMPONENT_ACCESS}`, role)
// delete role
export const deleteRole = role =>
  del(`${url.DELETE_ROLE}/${role}`, { headers: { role } })
// get role details
export const getRoleDetails = role =>
  get(`${url.GET_ROLE_DETAIL}/${role}`, role)
// get(`${url.GET_ROLE_DETAIL.replace(":id", id)}`);
//overide all user roles
export const overideAllUserRoles = data => post(url.OVERIDE_ALL_USER_ROLES, data)

// ---------------------------------------//--------------------------------------------------------------------------------
//MASTERS
// get facility
export const getFacilities = () => get(url.GET_FACILITIES)
// add facility
export const addNewFacility = facility => post(url.ADD_NEW_FACILITY, facility)
// update facility
export const updateFacility = (id, facility) =>
  post(`${url.UPDATE_FACILITY}/${id}`, facility)
// export const updateUser = (id ,facility) => put(`${url.UPDATE_COMPANY}/${id}`, facility);
// delete facility
export const deleteFacility = facility =>
  del(`${url.DELETE_FACILITY}/${facility}`, { headers: { facility } })
// get facility details
export const getFacilityDetails = id =>
  get(`${url.GET_FACILITY_DETAIL}/${id}`, id)
// get(`${url.GET_USER_DETAIL.replace(":id", id)}`);

export const getTickets = () => get(url.GET_TICKETS)
export const addNewTicket = ticket => post(url.ADD_NEW_TICKET, ticket)
export const updateTicket = (id, ticket) =>
  post(`${url.UPDATE_TICKET}/${id}`, ticket)
export const deleteTicket = ticket =>
  del(`${url.DELETE_TICKET}/${ticket}`, { headers: { ticket } })
export const getTicketDetails = id => get(`${url.GET_TICKET_DETAIL}/${id}`, id)
export const getCountForTicketsDashboard = () =>
  get(`${url.GET_COUNT_FOR_TICKETS_DASHBOARD}`)
export const getCriticalPriorityTicketList = () =>
  get(`${url.GET_CRITICAL_PRIORITY_TICKET_LIST}`)
export const getHighPriorityTicketList = () =>
  get(`${url.GET_HIGH_PRIORITY_TICKET_LIST}`)
export const getMediumPriorityTicketList = () =>
  get(`${url.GET_MEDIUM_PRIORITY_TICKET_LIST}`)
export const getLowPriorityTicketList = () =>
  get(`${url.GET_LOW_PRIORITY_TICKET_LIST}`)
export const getRecentTicketList = () => get(`${url.GET_RECENT_TICKETS_LIST}`)
export const getUserVendorWithMostTicket = () =>
  get(`${url.GET_USER_VENDOR_WITH_MOST_TICKET}`)
export const getOpenCloseTicketGraph = openCloseTicketGraph =>
  post(url.GET_OPEN_CLOSE_TICKET_GRAPH, openCloseTicketGraph)
export const getTicketStatusPieDigram = ticketStatusPieDigram =>
  post(url.GET_TICKETS_STATUS_PIE_DIGRAM, ticketStatusPieDigram)
export const getTicketPriorityPieDigram = ticketPriorityPieDigram =>
  post(url.GET_TICKETS_PRIORITY_PIE_DIGRAM, ticketPriorityPieDigram)

export const getDocuments = () => get(url.GET_DOCUMENTS)
export const getActiveDocuments = userType =>
  get(`${url.GET_ACTIVE_DOCUMENTS}/${userType}`)
export const addNewDocument = document => post(url.ADD_NEW_DOCUMENT, document)
export const updateDocument = (id, document) =>
  post(`${url.UPDATE_DOCUMENT}/${id}`, document)
export const deleteDocument = document =>
  del(`${url.DELETE_DOCUMENT}/${document}`, { headers: { document } })
export const getDocumentDetails = id =>
  get(`${url.GET_DOCUMENT_DETAIL}/${id}`, id)

export const getExpenses = () => get(url.GET_EXPENSES)
export const addNewExpense = expense => post(url.ADD_NEW_EXPENSE, expense)
export const updateExpense = (id, expense) =>
  post(`${url.UPDATE_EXPENSE}/${id}`, expense)
export const deleteExpense = expense =>
  del(`${url.DELETE_EXPENSE}/${expense}`, { headers: { expense } })
export const getExpenseDetails = id =>
  get(`${url.GET_EXPENSE_DETAIL}/${id}`, id)

export const getInvoices = () => get(url.GET_INVOICES)
export const addNewInvoice = invoice => post(url.ADD_NEW_INVOICE, invoice)
export const updateInvoice = (id, invoice) =>
  post(`${url.UPDATE_INVOICE}/${id}`, invoice)
export const deleteInvoice = invoice =>
  del(`${url.DELETE_INVOICE}/${invoice}`, { headers: { invoice } })
export const getInvoiceDetails = id =>
  get(`${url.GET_INVOICE_DETAIL}/${id}`, id)
export const getGstApplicableRateForInvoiceComponent = () =>
  get(url.GET_GST_APPLICABLE_RATE_FOR_INVOICE_COMPONENT)
export const getSACHSNCodeForInvoiceComponent = () =>
  get(url.GET_SAC_HSN_CODE_FOR_INVOICE_COMPONENT)

export const getLandlords = () => get(url.GET_LANDLORDS)
export const activeLandlord = () => get(url.ACTIVE_LANDLORD)
export const addNewLandlord = landlord => post(url.ADD_NEW_LANDLORD, landlord)
export const updateLandlord = (id, landlord) =>
  post(`${url.UPDATE_LANDLORD}/${id}`, landlord)
export const deleteLandlord = landlord =>
  del(`${url.DELETE_LANDLORD}/${landlord}`, { headers: { landlord } })
export const getLandlordDetails = id =>
  get(`${url.GET_LANDLORD_DETAIL}/${id}`, id)
export const getLandlordDocuments = id =>
  get(`${url.GET_DOCUMENT_LIST_LANDLORD}/${id}`, id)
export const addLandlordDocument = document =>
  postWithFile(url.ADD_DOCUMENT_LANDLORD, document)
export const deleteLandlordDocument = document =>
  del(`${url.DELETE_DOCUMENT_LANDLORD}/${document}`, { headers: { document } })

export const getSACHSNCode = () => get(url.GET_SACHSNCODE)
export const addNewSACHSNCode = codes => post(url.ADD_NEW_SACHSNCODE, codes)
export const updateSACHSNCode = (id, code) =>
  post(`${url.UPDATE_SACHSNCODE}/${id}`, code)
export const deleteSACHSNCode = code =>
  del(`${url.DELETE_SACHSNCODE}/${code}`, { headers: { code } })
export const getSACHSNCodeDetail = id =>
  get(`${url.GET_SACHSNCODE_DETAIL}/${id}`, id)

export const getMaterialMasterList = () => get(url.GET_MATERIAL_MASTER_LIST)
export const getMaterialMasterDetail = id =>
  get(`${url.GET_MATERIAL_MASTER_DETAIL}/${id}`, id)
export const addNewMaterialMaster = materials =>
  post(url.ADD_MATERIAL_MASTER, materials)
export const updateMaterialMaster = (id, material) =>
  post(`${url.UPDATE_MATERIAL_MASTER}/${id}`, material)
export const deleteMaterialMaster = material =>
  del(`${url.DELETE_MATERIAL_MASTER}/${material}`, { headers: { material } })
export const getSacHsnCodeForMaterialMaster = () =>
  get(url.GET_SAC_HSN_CODE_FOR_MATERIAL_MASTER)
export const addNewSACHSNCodeMaterialMaster = codes =>
  post(url.ADD_NEW_SACHSNCODE_MATERIAL_MASTER, codes)

export const getUnitMasterList = () => get(url.GET_UNIT_MASTER_LIST)
export const getUnitMasterDetail = id =>
  get(`${url.GET_UNIT_MASTER_DETAIL}/${id}`, id)
export const addNewUnitMaster = units => post(url.ADD_NEW_UNIT_MASTER, units)
export const updateUnitMaster = (id, unit) =>
  post(`${url.UPDATE_UNIT_MASTER}/${id}`, unit)
export const deleteUnitMaster = unit =>
  del(`${url.DELETE_UNIT_MASTER}/${unit}`, { headers: { unit } })

export const getMaintainences = () => get(url.GET_MAINTAINENCES)
export const addNewMaintainence = maintainence =>
  post(url.ADD_NEW_MAINTAINENCE, maintainence)
export const updateMaintainence = (id, maintainence) =>
  post(`${url.UPDATE_MAINTAINENCE}/${id}`, maintainence)
export const deleteMaintainence = maintainence =>
  del(`${url.DELETE_MAINTAINENCE}/${maintainence}`, {
    headers: { maintainence },
  })
export const getMaintainenceDetails = id =>
  get(`${url.GET_MAINTAINENCE_DETAIL}/${id}`, id)

export const getCompliances = () => get(url.GET_COMPLIANCES)
export const getProjectCompliances = () => get(url.GET_PROJECT_COMPLIANCES)
export const getActiveCompliances = () => get(url.GET_ACTIVE_COMPLIANCES)
export const addNewCompliance = compliance =>
  post(url.ADD_NEW_COMPLIANCE, compliance)
export const updateCompliance = (id, compliance) =>
  post(`${url.UPDATE_COMPLIANCE}/${id}`, compliance)
export const deleteCompliance = compliance =>
  del(`${url.DELETE_COMPLIANCE}/${compliance}`, { headers: { compliance } })
export const getComplianceDetails = id =>
  get(`${url.GET_COMPLIANCE_DETAIL}/${id}`, id)

export const getUnits = () => get(url.GET_UNITS)
export const addNewUnit = unit => post(url.ADD_NEW_UNIT, unit)
export const updateUnit = (id, unit) => post(`${url.UPDATE_UNIT}/${id}`, unit)
export const deleteUnit = unit =>
  del(`${url.DELETE_UNIT}/${unit}`, { headers: { unit } })
export const getUnitDetails = id => get(`${url.GET_UNIT_DETAIL}/${id}`, id)

//Active Units
export const getActiveUnits = () => get(url.GET_ACTIVE_UNITS)

export const getVendors = () => get(url.GET_VENDORS)
export const addNewVendor = vendor => post(url.ADD_NEW_VENDOR, vendor)
export const updateVendor = (id, vendor) =>
  post(`${url.UPDATE_VENDOR}/${id}`, vendor)
export const deleteVendor = vendor =>
  del(`${url.DELETE_VENDOR}/${vendor}`, { headers: { vendor } })
export const getVendorDetails = id => get(`${url.GET_VENDOR_DETAIL}/${id}`, id)

// reason master
export const getReasonMasterList = () => get(url.GET_REASON_MASTER_LIST)
export const getReasonMasterDetail = id =>
  get(`${url.GET_REASON_MASTER_DETAIL}/${id}`, id)
export const addNewReasonMaster = materials =>
  post(url.ADD_NEW_REASON_MASTER, materials)
export const updateReasonMaster = (id, material) =>
  post(`${url.UPDATE_REASON_MASTER}/${id}`, material)
export const deleteReasonMaster = material =>
  del(`${url.DELETE_REASON_MASTER}/${material}`, { headers: { material } })

// vendor type master

export const getVendorTypeMasterList = () => get(url.GET_VENDORTYPE_MASTER_LIST)
export const getVendorTypeForVendorServiceMaster = () => get(url.GET_VENDOR_TYPE_FOR_VENDOR_SERVICE_LIST)
export const getVendorTypeMasterDetail = id =>
  get(`${url.GET_VENDORTYPE_MASTER_DETAIL}/${id}`, id)
export const addNewVendorTypeMaster = materials =>
  post(url.ADD_NEW_VENDORTYPE_MASTER, materials)
export const updateVendorTypeMaster = (id, material) =>
  post(`${url.UPDATE_VENDORTYPE_MASTER}/${id}`, material)
export const deleteVendorTypeMaster = material =>
  del(`${url.DELETE_VENDORTYPE_MASTER}/${material}`, { headers: { material } })
//proposal type
export const getProposalMasterList = () =>
  get(url.GET_PROPOSAL_TYPE_MASTER_LIST)
export const getProposalMasterDetail = id =>
  get(`${url.GET_PROPOSAL_TYPE_MASTER_DETAIL}/${id}`, id)
export const addNewProposalMaster = materials =>
  post(url.ADD_PROPOSAL_TYPE_MASTER, materials)
export const updateProposalMaster = (id, material) =>
  post(`${url.UPDATE_PROPOSAL_TYPE_MASTER}/${id}`, material)
export const deleteProposalMaster = material =>
  del(`${url.DELETE_PROPOSAL_TYPE_MASTER}/${material}`, {
    headers: { material },
  })
//CLIENTS

export const getClients = id => get(`${url.GET_CLIENTS}/${id}`, id)
export const getClientsList = id => get(`${url.GET_CLIENTS_LIST}`, id)
export const addNewClient = client => postWithFile(url.ADD_NEW_CLIENT, client)
export const updateClient = (id, client) =>
  postWithFile(`${url.UPDATE_CLIENT}/${id}`, client)
export const deleteClient = client =>
  del(`${url.DELETE_CLIENT}/${client}`, { headers: { client } })
export const getClientCode = () => get(url.GET_CLIENT_CODE)

// CLIENTS DOCUMENTS

export const getClientDocuments = id =>
  get(`${url.GET_CLIENT_DOCUMENTS}/${id}`, id)
export const addClientDocuments = document =>
  postWithFile(url.ADD_NEW_CLIENT_DOCUMENT, document)
export const deleteClientDocument = document =>
  del(`${url.DELETE_CLIENT_DOCUMENT}/${document}`, { headers: { document } })

//CLIENTS ASSIGNED PROJECT
export const getAssignedProjectUnit = client =>
  get(`${url.GET_ASSIGNED_PROJECT}/${client}`, { headers: { client } })
export const getAssignedProjectUnitList = client =>
  get(`${url.GET_ASSIGNED_PROJECT_LIST}/${client}`, { headers: { client } })
export const addAssignedProjectUnit = (id, client) =>
  postWithFile(`${url.ADD_ASSIGNED_PROJECT}/${id}`, client)
export const updateAssignedProjectUnit = (id, client) =>
  postWithFile(`${url.UPDATE_ASSIGNED_PROJECT}/${id}`, client)
export const deleteAssignedProjectUnit = client =>
  del(`${url.DELETE_ASSIGNED_PROJECT}/${client}`, { headers: { client } })
export const getAllAssignedProjects = () =>
  get(url.GET_ALL_ASSIGNED_PROJECT_UNITS)
export const approveProject = (id, status) =>
  post(`${url.APPROVE_PROJECT}/${id}`, status)

export const getProjectListForClients = id =>
  get(`${url.GET_PROJECT_LIST_FOR_CLIENT}/${id}`, { headers: { id } })
export const getProjectUnitListForClients = client =>
  get(`${url.GET_PROJECT_UNIT_LIST_FOR_CLIENT}/${client}`, {
    headers: { client },
  })
export const getSacHsnCodeClient = () => get(`${url.GET_SACHSN_CODE_BILLING}`)

export const getInvoiceTypeMasterClientList = () =>
  get(url.GET_INVOICE_TYPE_MASTER_CLIENT_LIST)
//REINVITE CLIENT

export const reinviteClient = reinvite =>
  post(`${url.REINVITE_CLIENT}/${reinvite}`, reinvite)

//CLIENTS SERVICE ENGAGEMENT

export const getAllEngagementList = () => get(url.GET_ALL_ENGAGEMENT_LIST)
export const getApprovalPendingList = () => get(url.GET_APPROVAL_PENDING_LIST)
export const getBillingSetupPendingList = () =>
  get(url.GET_BILLING_SETUP_PENDING_LIST)
export const getApplicableRateClientList = () =>
  get(url.GET_APPLICABLE_RATE_CLIENT_LIST)

//VENDORS Management

export const getVendor = id => get(`${url.GET_VENDOR}/${id}`, id)
export const getVendorContact = id => get(`${url.GET_VENDOR_CONTACT}/${id}`, id)
export const getVendorsList = () => get(url.GET_VENDORS_LIST)
export const getVendorServiceMasterList = () =>
  get(url.GET_VENDOR_SERVICE_MASTER_LIST)
export const addNewVendors = vendor => postWithFile(url.ADD_NEW_VENDORS, vendor)
export const updateNewVendors = (id, vendor) =>
  postWithFile(`${url.UPDATE_NEW_VENDORS}/${id}`, vendor)
export const addNewVendorsDocuments = (id, docs) =>
  postWithFile(`${url.ADD_NEW_VENDORS_DOCUMENTS}/${id}`, docs)
export const addNewVendorsContacts = (id, contacts) =>
  post(`${url.ADD_NEW_VENDORS_CONTACTS}/${id}`, contacts)
export const updateNewVendorsContacts = (id, contacts) =>
  post(`${url.UPDATE_VENDORS_CONTACTS}/${id}`, contacts)
export const updateVendors = (id, vendor) =>
  post(`${url.UPDATE_VENDOR}/${id}`, vendor)
export const deleteVendors = vendor =>
  del(`${url.DELETE_VENDORS}/${vendor}`, { headers: { vendor } })
export const deleteVendorsDocument = vendor =>
  del(`${url.DELETE_VENDOR_DOCUMENT}/${vendor}`, { headers: { vendor } })
export const deleteVendorsContact = vendor =>
  del(`${url.DELETE_VENDOR_CONTACT}/${vendor}`, { headers: { vendor } })
export const reinviteVendor = reinvite =>
  post(`${url.REINVITE_VENDORS}/${reinvite}`, reinvite)
export const getVendorCode = () => get(url.GET_VENDOR_CODE)
export const addVendorPayment = data => post(url.ADD_VENDOR_PAYMENT, data)
export const getVendorListInPayment = projectId =>
  get(`${url.GET_VENDOR_LIST_IN_PAYMENT}/${projectId}`)
export const getVendorPaymentDetail = vendorId =>
  get(`${url.GET_VENDOR_PAYMENT_DETAIL}/${vendorId}`)
export const getVendorPaymentData = paymentId =>
  get(`${url.GET_VENDOR_PAYMENT_DATA}/${paymentId}`)
export const approveVendorInvoice = (value, data) =>
  post(`${url.APPROVE_VENDOR_INVOICE}/${data}`, value)

//contracts
export const getVendorContractList = (vendorId) => get(`${url.GET_VENDOR_CONTRACT_LIST}/${vendorId}`)
export const getVendorContractDetail = (vendorId) => get(`${url.GET_VENDOR_CONTRACT}/${vendorId}`)
export const addVendorContracts = (data) => postWithFile(`${url.ADD_VENDOR_CONTRACT}`,data)
export const updateVendorContracts = (id,data) => postWithFile(`${url.UPDATE_VENDOR_CONTRACT}/${id}`,data)
export const deleteVendorsContract = vendor =>
  del(`${url.DELETE_VENDOR_CONTRACT}/${vendor}`)




//SERVICE REQUEST
export const getServiceRequests = () => get(`${url.GET_SERVICE_REQUESTS}`)
export const getServiceRequestType = () =>
  get(`${url.GET_SERVICE_REQUEST_TYPE}`)
export const getServiceRequestDetails = id =>
  get(`${url.GET_SERVICE_REQUEST_DETAIL}/${id}`)
export const addNewServiceRequest = (clientId, serviceRequest) =>
  post(`${url.ADD_NEW_SERVICE_REQUEST}/${clientId}`, serviceRequest)
export const assignServiceRequest = (serviceId, serviceRequest) =>
  post(`${url.ASSIGN_SERVICE_REQUEST}/${serviceId}`, serviceRequest)
export const updateServiceRequest = () => post(url.UPDATE_SERVICE_REQUEST)
export const deleteServiceRequest = () => del(url.DELETE_SERVICE_REQUEST)
export const getProjectUnitCategory = () =>
  get(`${url.GET_PROJECT_UNIT_CATEGORY}`)
export const getMaintenanceIssues = () => get(`${url.GET_MAINTENANCE_ISSUES}`)
export const getClientInvoices = () => get(`${url.GET_CLIENT_INVOICES}`)
export const getSupportTicket = () => get(`${url.GET_SUPPORT_TICKET}`)
export const getAllServiceRequestList = data =>
  post(`${url.GET_ALL_SERVICE_REQUEST_LIST}/${data.user_id}`, data)
export const getAllServiceRequestListStatusWise = data =>
  post(`${url.GET_ALL_SERVICE_REQUEST_LIST_STATUS_WISE}`, data)
export const getPendingServiceRequestList = () =>
  get(`${url.GET_PENDING_SERVICE_REQUEST_LIST}`)
export const getProjectManagerInServiceRequest = () =>
  get(`${url.GET_PROJECT_MANAGER_IN_SERVICE_REQUEST}`)
export const getUsersInServiceRequest = id =>
  get(`${url.GET_USERS_IN_SERVICE_REQUEST}/${id}`)
export const getVendorsInServiceRequest = () =>
  get(`${url.GET_VENDORS_IN_SERVICE_REQUEST}`)
export const createServiceRequest = (serviceId, serviceRequest) =>
  postWithFile(`${url.CREATE_SERVICE_REQUEST}/${serviceId}`, serviceRequest) //files added

export const acceptServiceRequest = (serviceId, serviceRequest) =>
  post(`${url.ACCEPT_SERVICE_REQUEST}/${serviceId}`, serviceRequest)
export const getMaterialMasterForServiceRequest = () =>
  get(`${url.GET_MATERIAL_MASTER_FOR_SERVICE_REQUEST}`)
export const getUnitMasterForServiceRequest = () =>
  get(`${url.GET_UNIT_MASTER_FOR_SERVICE_REQUEST}`)
export const getCountForServiceRequestDashboard = () =>
  get(`${url.GET_COUNT_FOR_SERVICE_REQUEST_DASHBOARD}`)
export const getLookingForadditionalProjectUnitList = () =>
  get(`${url.GET_LOOKING_FOR_ADDITIONAL_PROJECT_UNIT_LIST}`)
export const getFacingMaintenanceIssueList = () =>
  get(`${url.GET_FACING_MAINTENANCE_ISSUE_LIST}`)
export const getSupportTicketList = () => get(`${url.GET_SUPPORT_TICKET_LIST}`)
export const getRecentServiceRequestList = () =>
  get(`${url.GET_RECENT_SERVICE_REQUEST_LIST}`)
export const getTypesOfRequestsGraph = typesOfRequestsGraph =>
  post(url.GET_TYPES_OF_REQUESTS_GRAPH, typesOfRequestsGraph)
export const getServiceRequestsAssignmentGraph = requestsAssignmentGraph =>
  post(url.GET_SERVICE_REQUESTS_ASSIGNMENT_GRAPH, requestsAssignmentGraph)
export const getServiceRequestStatusPieDigram = requestsPieDigram =>
  post(url.GET_SERVICE_REQUESTS_STATUS_PIE_DIGRAM, requestsPieDigram)
//approve single single approval by project manager
export const approveEstimation = ( data) =>
  post(`${url.APPROVE_ESTIMATION}`, data)

export const getCities = stateId => get(`${url.GET_CITIES}/${stateId}`)
export const getStates = states => get(`${url.GET_STATES}/${states}`)
export const getCountries = () => get(url.GET_COUNTRIES)
export const getProjectListByCustomerId = (id) => get(`${url.GET_PROJECT_LIST_BY_CUSTOMER_ID}/${id}`)
export const getProjectListByVendorId = (id) => get(`${url.GET_PROJECT_LIST_BY_VENDOR_ID}/${id}`)
export const getProjectUnitData = (id) => get(`${url.GET_PROJECT_UNIT_DATA}/${id}`)

//Billing Setup and Billing

export const getBillingClientList = id =>
  get(`${url.GET_BILLING_CLIENT_LIST}/${id}`) //billing
export const getBillingClientListInReceipt = id =>
  get(`${url.GET_BILLING_CLIENT_LIST_IN_RECEIPT}/${id}`) //billing receipt
export const getBillingCompanyListInReceipt = id =>
  get(`${url.GET_BILLING_COMPANY_LIST_IN_RECEIPT}/${id}`) //billing receipt
export const getBillingClientDetail = clientId =>
  get(`${url.GET_BILLING_CLIENT_DETAIL}/${clientId}`)
export const getBillingCompanyDetailInReceipt = companyId =>
  get(`${url.GET_BILLING_COMPANY_DETAIL_IN_RECEIPT}/${companyId}`)
export const getEngagementService = projectId =>
  get(`${url.GET_SERVICE_ENGAGEMENT}/${projectId}`)
export const getBillingCompanyList = () =>
  get(`${url.GET_BILLING_COMPANY_LIST}/${unit_id}`)
export const getBillingCompanyDetail = companyId =>
  get(`${url.GET_BILLING_COMPANY_DETAIL}/${companyId}`)

export const getSupplyList = () => get(url.GET_SUPPLY_LIST)
export const getPlaceOfSupply = () => get(url.GET_PLACE_OF_SUPPLY)
export const getApplicableRateList = () => get(url.GET_APPLICABLE_RATE_LIST)
export const getInvoiceTypeMasterList = () =>
  get(url.GET_INVOICE_TYPE_MASTER_LIST)
//Billing Setup
export const addBillingSetup = bill => post(url.ADD_BILLING_SETUP, bill)
export const editBillingSetup = (billId, bill) =>
  post(`${url.EDIT_BILLING_SETUP}/${billId}`, bill)
export const getBillingSetupDetail = billId =>
  get(`${url.GET_BILLING_SETUP_DETAIL}/${billId}`)
export const addNewBill = bill => post(url.ADD_NEW_BILL, bill)
export const editBill = (billingId, bill) =>
  post(`${url.EDIT_BILL}/${billingId}`, bill)
export const getBillingRegisterList = bill =>
  post(url.GET_BILLING_REGISTER, bill)
export const getBillingData = bill =>
  get(`${url.GET_BILLING_DATA}/${bill}`, bill)

export const getInvoiceNumber = companyId =>
  post(url.GET_INVOICE_NUMBER, companyId)
//creditNote
export const getBillingCreditNoteList = bill =>
  post(url.GET_BILLING_CREDIT_NOTE_LIST, bill)
export const getCreditNumber = companyId =>
  post(url.GET_CREDIT_NUMBER, companyId)
export const getComponentsForCreditNote = data =>
  get(`${url.GET_COMPONENTS_FOR_CREDIT_NOTE}/${data}`)
export const getCreditNoteReasons = data =>
  get(`${url.GET_CREDIT_NOTE_REASONS}`)
export const getCreditNoteInvoiceList = data =>
  get(`${url.GET_CREDIT_NOTE_INVOICE_LIST}/${data}`)
export const sendCreditNoteMailToCustomer = (customerId, billId) =>
  post(`${url.SEND_CREDIT_NOTE_MAIL_TO_CUSTOMER}/${customerId}`, billId)

export const deleteCreditNote = billId =>
  del(`${url.DELETE_CREDIT_NOTE}/${billId}`, { headers: { billId } })

export const addCreditNote = bill => post(url.ADD_CREDIT_NOTE, bill)
export const editCreditNote = (billingId, bill) =>
  post(`${url.EDIT_CREDIT_NOTE}/${billingId}`, bill)
export const getCreditData = bill => get(`${url.GET_CREDIT_DATA}/${bill}`, bill)
//
export const getRecurringBillList = () => get(url.GET_RECURRING_BILL)
export const getInvoicePreviewDetail = bill =>
  get(`${url.GET_INVOICE_PREVIEW_DETAIL}/${bill}`, bill)
export const getCreditInvoicePreviewDetail = bill =>
  get(`${url.GET_CREDIT_INVOICE_PREVIEW_DETAIL}/${bill}`, bill)
export const getPaymentmode = () => get(`${url.GET_PAYMENT_MODE}`)
export const addRecordPayment = bill => post(`${url.ADD_RECORD_PAYMENT}`, bill)
export const deleteBillingRegister = billId =>
  del(`${url.DELETE_BILLING_REGISTER}/${billId}`, { headers: { billId } })

export const deleteBillingRecord = billId =>
  del(`${url.DELETE_BILLING_RECORD}/${billId}`, { headers: { billId } })
export const getRecordPaymentDetail = billId =>
  get(`${url.GET_RECORD_PAYMENT_DETAIL}/${billId}`)
export const getSacHsnCodeBillingSetup = () =>
  get(`${url.GET_SACHSN_CODE_BILLING_SETUP}`)
export const getSacHsnCodeBilling = () => get(`${url.GET_SACHSN_CODE_BILLING}`)
//new flow create new bill
export const getAssignedProjectUnitListInBilling = value =>
  post(`${url.GET_ASSIGNED_PROJECT_UNIT_IN_BILLING}`, value)
export const getProjectListInBilling = id =>
  get(`${url.GET_PROJECT_LIST_IN_BILLING}/${id}`)
export const getProjectDetailInBilling = projectId =>
  get(`${url.GET_PROJECT_DETAIL_IN_BILLING}/${projectId}`)
export const getProjectUnitDetailInBilling = projectUnitId =>
  get(`${url.GET_PROJECT_UNIT_DETAIL_IN_BILLING}/${projectUnitId}`)

//receipt
export const getBillingReceiptList = value =>
  post(`${url.GET_BILLING_RECEIPT_LIST}`, value)
export const addNewBillingReceipt = bill => post(url.ADD_BILLING_RECEIPT, bill)
export const editBillingReceipt = (billingId, bill) =>
  post(`${url.EDIT_BILLING_RECEIPT}/${billingId}`, bill)

export const getBillInvoiceNoList = companyId =>
  get(`${url.GET_BILL_INVOICE_NO_LIST}/${companyId}`)
export const getReceiptNumber = companyId =>
  post(url.GET_RECEIPT_NUMBER, companyId)
export const sendMailToCustomer = (customerId, billId) =>
  post(`${url.SEND_MAIL_TO_CUSTOMER}/${customerId}`, billId)

export const downloadInvoice = billId =>
  get(`${url.DOWNLOAD_INVOICE}/${billId}`, billId)
export const getBillingReceiptDetail = paymentId =>
  get(`${url.GET_BILLING_RECEIPT_DETAIL}/${paymentId}`)
export const getOutstandingAmountDetailInBillingReceipt = invoiceId =>
  get(`${url.GET_OUTSTANDING_AMOUNT_DETAIL_IN_BILLING_RECEIPT}/${invoiceId}`)
//
export const getGstLocationList = id =>
  get(`${url.GET_GST_LOCATION_LIST}/${id}`)
export const getGstLocationDetail = id =>
  get(`${url.GET_GST_LOCATION_DETAIL}/${id}`)

//FILTERS FOR REGISTER
export const getCompanyFilter = id => get(`${url.GET_COMPANY_FILTER}/${id}`)
export const getCompanyLocationFilter = id =>
  get(`${url.GET_COMPANY_LOCATION_FILTER}/${id}`)
export const getCustomerFilter = id => get(`${url.GET_CUSTOMER_FILTER}/${id}`)
export const getProjectFilter = id => get(`${url.GET_PROJECT_FILTER}/${id}`)
export const getFinancialYearFilter = id =>
  get(`${url.GET_FINANCIAL_YEAR_FILTER}`)
//FILTERS FOR RECEIPT
export const getCompanyFilterForReceipt = id =>
  get(`${url.GET_COMPANY_FILTER_FOR_RECEIPT}/${id}`)
export const getCompanyLocationFilterForReceipt = id =>
  get(`${url.GET_COMPANY_LOCATION_FILTER_FOR_RECEIPT}/${id}`)
export const getCustomerFilterForReceipt = id =>
  get(`${url.GET_CUSTOMER_FILTER_FOR_RECEIPT}/${id}`)
export const getProjectFilterForReceipt = id =>
  get(`${url.GET_PROJECT_FILTER_FOR_RECEIPT}/${id}`)
export const getFinancialYearFilterForReceipt = id =>
  get(`${url.GET_FINANCIAL_YEAR_FILTER_FOR_RECEIPT}`)
export const sendReceiptEmailToCustomer = (customerId, receiptId) =>
  post(`${url.SEND_RECEIPT_MAIL_TO_CUSTOMER}/${customerId}`, receiptId)
//RECEIPT PREVIEW

export const getReceiptPreview = id => get(`${url.GET_RECEIPT_PREVIEW}/${id}`)

//COnsumption
export const addNewConsumption = consumption =>
  post(url.ADD_NEW_CONSUMPTION, consumption)
export const editConsumption = (consumptionId, consumption) =>
  post(`${url.EDIT_CONSUMPTION}/${consumptionId}`, consumption)
export const getConsumptionRegisterList = consumption =>
  post(url.GET_CONSUMPTION_REGISTER_LIST, consumption)
export const getConsumptionData = consumption =>
  post(`${url.GET_CONSUMPTION_DATA}`, consumption)
export const deleteConsumptionRegister = consumptionId =>
  del(`${url.DELETE_CONSUMPTION_REGISTER}/${consumptionId}`, {
    headers: { consumptionId },
  })

export const getConsumptionForBilling = consumption =>
  post(url.GET_CONSUMPTION_IN_BILLING, consumption)

//reports
export const downloadGstR1Excell = value =>
  downloadExcel(`${url.DOWNLOAD_GSTR1_EXCELL}`, value)
export const downloadOccupancyExcell = id =>
  get(`${url.DOWNLOAD_PROJECT_OCCUPANCY_EXCELL}/${id}`)

export const getAllProjects = id => get(`${url.GET_ALL_PROJECTS}/${id}`)
export const getProjectOccupancyDetails = id =>
  get(`${url.GET_PROJECT_OCCUPANCY_DETAIL}/${id}`)
//GST R1 REPORT
export const getAllCompanies = () => get(`${url.GET_ALL_COMPANIES}`)
export const getGstR1Report = companyData =>
  post(`${url.GET_GST_R1_REPORT}`, companyData)

//CUSTOMER Aging 
export const getAllCustomerAgingReportList = (data) => post(`${url.GET_ALL_CUSTOMER_AGING_REPORT}/${data.project_id}`,data)
export const getCustomerAgingReport = (data) => post(`${url.GET_CUSTOMER_AGING_REPORT}`,data)
//VENDOR Aging 
export const getAllVendorAgingReportList = (data) => post(`${url.GET_ALL_VENDOR_AGING_REPORT}`,data)
export const getVendorAgingReport = () => post(`${url.GET_VENDOR_AGING_REPORT}`)

//project compliance certificate  
export const getProjectComplianceCountList = (data) => get(`${url.GET_PROJECT_COMPLIANCE_COUNT_LIST}/${data.user_id}`,data)
export const getProjectComplianceDetail = (data) => post(`${url.GET_PROJECT_COMPLIANCE_DETAIL}`,data)

//vendor contract renewal
export const getVendorContractRenewalCountList = (data) => get(`${url.GET_VENDOR_CONTRACT_RENEWAL_COUNT_LIST}/${data.user_id}`,data)
export const getVendorContractRenewalDetail = (data) => post(`${url.GET_VENDOR_CONTRACT_RENEWAL_DETAIL}`,data)


//GET SERVICE TICKETS
export const getServiceRequestTicketList = data =>
  post(`${url.GET_SERVICE_TICKETS_LIST}/${data.user_id}`, data)
export const getServiceRequestTicketDetail = id =>
  get(`${url.GET_SERVICE_TICKETS_DETAIL}/${id}`)

// delete material manpower other cost in SERVICE TICKETS
export const deleteManpower = id =>
  del(`${url.DELETE_MANPOWER}/${id}`, { headers: { id } })
export const deleteMaterial = id =>
  del(`${url.DELETE_MATERIAL}/${id}`, { headers: { id } })
export const deleteOtherCost = id =>
  del(`${url.DELETE_OTHER_COST}/${id}`, { headers: { id } })

//add proposal, material othercost and manpower
export const addMaterialInTicket = (ticketId, data) =>
  postWithFile(`${url.ADD_MATERIAL_IN_TICKET}/${ticketId}`, data)
export const addManpowerInTicket = (ticketId, data) =>
  postWithFile(`${url.ADD_MANPOWER_IN_TICKET}/${ticketId}`, data)
export const addOtherCostInTicket = (ticketId, data) =>
  postWithFile(`${url.ADD_OTHERCOST_IN_TICKET}/${ticketId}`, data)
export const sendForReviewInTicket = (ticketId, data) =>
  postWithFile(`${url.SEND_FOR_REVIEW_IN_TICKET}/${ticketId}`, data)
export const updateStatusInTicket = (ticketId, data) =>
  post(`${url.UPDATE_STATUS_IN_TICKET}/${ticketId}`, data)
export const sendProgressInTicket = (ticketId, data) =>
  postWithFile(`${url.SEND_PROGRESS_IN_TICKET}`, data)
//renewals

// export const getRenewals = (id) => get(`${url.GET_RENEWALS}`)
export const getRenewals = id => get(`${url.GET_RENEWALS}/${id}`)
export const addNewRenewal = (clientId, renewals) =>
  post(`${url.ADD_NEW_RENEWAL}/${clientId}`, renewals)
export const updateRenewal = (id, renewals) =>
  post(`${url.UPDATE_RENEWAL}/${id}`, renewals)
export const declineRenewal = (id, renewals) =>
  post(`${url.DECLINE_RENEWAL}/${id}`, renewals)

export const getRenewalDetails = id => post(`${url.GET_RENEWAL_DETAIL}`, id)

export const sendReminderMailToCustomer = (customerId, billId) =>
  postWithFile(`${url.SEND_REMINDER_MAIL_TO_CUSTOMER}/${customerId}`, billId)

//consumption list filter

export const getCustomerFilterForConsumption = id =>
  get(`${url.GET_CUSTOMER_FILTER_FOR_CONSUMPTION}/${id}`)
export const getProjectFilterForConsumption = id =>
  get(`${url.GET_PROJECT_FILTER_FOR_CONSUMPTION}/${id}`)
export const getUnitForConsumption = value =>
  post(`${url.GET_UNIT_FOR_CONSUMPTION}`, value)
export const getConsumptionFilter = value =>
  post(`${url.GET_CONSUMPTION_FILTER}`, value)

//vendor payment filter
export const getProjectFilterForVendorPayment = id =>
  get(`${url.GET_PROJECT_FILTER_FOR_VENDOR_PAYMENT}/${id}`)
export const getVendorFilterForVendorPayment = id =>
  get(`${url.GET_VENDOR_FILTER_FOR_VENDOR_PAYMENT}/${id}`)
export const getCompanyFilterForVendorPayment = id =>
  get(`${url.GET_COMPANY_FILTER_FOR_VENDOR_PAYMENT}/${id}`)
export const getBillHistoryForVendorPayment = data =>
  post(`${url.GET_BILL_HISTORY_FOR_VENDOR_PAYMENT}`, data)
export const getPaymentHistoryForVendorPayment = data =>
  post(`${url.GET_PAYMENT_HISTORY_FOR_VENDOR_PAYMENT}`, data)
export const getPaymentInvoiceNumber = data =>
  post(`${url.GET_PAYMENT_INVOICE_NUMBER}`, data)
export const deletePaymentHistoryForVendorPayment = data =>
  del(`${url.DELETE_PAYMENT_HISTORY_FOR_VENDOR_PAYMENT}/${data}`)

export const getProjectFilterForVendorPaymentList = id =>
  get(`${url.GET_PROJECT_FILTER_FOR_VENDOR_PAYMENT_LIST}/${id}`)
export const getVendorFilterForVendorPaymentList = id =>
  get(`${url.GET_VENDOR_FILTER_FOR_VENDOR_PAYMENT_LIST}/${id}`)

//customer oustanding report
export const getAllProjectsForCustomerOustanding = id =>
  get(`${url.GET_ALL_PROJECTS_FOR_CUSTOMER_OUTSTANDING}/${id}`)
export const getFinancialYearForOutstanding = () =>
  get(`${url.GET_FINANCIAL_YEAR_FOR_OUTSTANDING}`)
export const getLedgerDetails = data =>
  post(`${url.GET_LEDGER_DETAIL}/${data.client_id}`, data)
export const getAllCompaniesForCustomerOustanding = id =>
  get(`${url.GET_ALL_COMPANIES_FOR_CUSTOMER_OUTSTANDING}`)
export const getReportForCustomerOustanding = data =>
  post(`${url.GET_REPORT_FOR_CUSTOMER_OUTSTANDING}`, data)
export const downloadLedger = data =>
  downloadPDF(`${url.Download_LEDGER}/${data.client_id}`, data)
export const downloadOutstandingReportExcel = data =>
  downloadExcel(`${url.Download_OUTSTANDING_REPORT_EXCEL}`, data)

//vendor Summary report
export const getVendorInvoices = () => get(`${url.GET_VENDOR_SUMMARY_REPORT}`)
export const getVendorInvoicesData = id =>
  get(`${url.GET_VENDOR_INVOICE_DATA}/${id}`)
export const getVendorInvoicesDataWithTds = id =>
  get(`${url.GET_VENDOR_INVOICE_DATA_WITH_TDS}/${id}`)
export const getVendorInvoicesDataWithOutstandingAmount = id =>
  get(`${url.GET_VENDOR_INVOICES_DATA_WITH_OUTSTANDING_AMOUNT}/${id}`)
export const getVendorInvoicesPaymentData = id =>
  get(`${url.GET_VENDOR_INVOICES_PAYMENT_DATA}/${id}`)
export const getVendorTicketData = id =>
  get(`${url.GET_VENDOR_TICKET_DATA}/${id}`)
//vendorLedger
export const getVendorLedger = (data) => post(`${url.GET_VENDOR_LEDGER}/${data.vendor_id}`,data)
export const downloadVendorLedger = data =>
  downloadPDF(`${url.Download_VENDOR_LEDGER}/${data.vendor_id}`, data)

//customer Index
export const getCityForCustomerIndex = id =>
  get(`${url.GET_CITY_FOR_CUSTOMER_INDEX}`)
export const getCustomerIndex = data => post(`${url.GET_CUSTOMER_INDEX}`, data)
export const getProjectFilterForCustomerIndex = id =>
  get(`${url.GET_PROJECT_FOR_CUSTOMER_INDEX}/${id}`)
export const getStateForCustomerIndex = id =>
  get(`${url.GET_STATE_FOR_CUSTOMER_INDEX}`)
export const downloadCustomerIndex = data =>
  downloadExcel(url.DOWNLOAD_CUSTOMER_INDEX, data)

//PROPOSAL
export const getProposals = value => post(`${url.GET_PROPOSALS}`, value)
export const getProposalDetails = id => get(`${url.GET_PROPOSAL_DETAIL}/${id}`)
export const addNewProposal = proposal =>
  postWithFile(url.ADD_NEW_PROPOSAL, proposal)
export const updateProposal = () => post(url.UPDATE_PROPOSAL)
export const deleteProposal = () => del(url.DELETE_PROPOSAL)
export const AcceptProposal = data => post(url.ACCEPT_PROPOSAL, data)

//vendor debit note
export const getVendorInvoicesInDebitNote = data =>
  post(`${url.GET_VENDOR_INVOICE_IN_DEBIT_NOTE}`, data)

export const getDebitNoteList = value =>
  post(`${url.GET_DEBIT_NOTE_LIST}`, value)
export const getDebitNoteDetail = id =>
  get(`${url.GET_DEBIT_NOTE_DETAIL}/${id}`)
export const addNewDebitNote = data => post(url.ADD_NEW_DEBIT_NOTE, data)
export const updateDebitNote = (id, data) =>
  post(`${url.UPDATE_DEBIT_NOTE}/${id}`, data)
export const deleteDebitNote = id => del(`${url.DELETE_DEBIT_NOTE}/${id}`)
export const getVendorInvoiceDetail = id =>
  get(`${url.GET_VENDOR_INVOICE_DETAIL}/${id}`)

export const getGoodsServiceList = () => get(`${url.GET_GOODS_SERVICES_LIST}`)

//manage client users
export const getClientUserList = value =>
  get(`${url.GET_CLIENT_USER_LIST}/${value}`, value)
export const getClientUserDetail = id =>
  get(`${url.GET_CLIENT_USER_DETAIL}/${id}`)
export const addNewClientUser = data => postWithFile(url.ADD_NEW_CLIENT_USER, data)
export const updateClientUser = (id, data) =>
  postWithFile(`${url.UPDATE_CLIENT_USER}/${id}`, data)
export const deleteClientUser = id => del(`${url.DELETE_CLIENT_USER}/${id}`)
export const reinviteClientUser = id => get(`${url.REINVITE_CLIENT_USER}/${id}`)

export const getActiveClientProjectUnit = id => get(`${url.GET_ACTIVE_CLIENT_PROJECT_UNIT}/${id}`)



//export excell
export const downloadBillingRegisterExcel = data =>
  downloadExcel(url.DOWNLOAD_BILLING_REGISTER_EXCEL, data)
export const downloadReceiptExcel = data =>
  downloadExcel(url.DOWNLOAD_RECEIPT_EXCEL, data)
export const downloadDebitNoteExcel = data =>
  downloadExcel(url.DOWNLOAD_DEBIT_NOTE_EXCEL, data)
export const downloadCreditNoteExcel = data =>
  downloadExcel(url.DOWNLOAD_CREDIT_NOTE_EXCEL, data)
export { login, logout }


//get all  notifications
export const getAllNotificationList = (data) => post(`${url.GET_ALL_NOTIFICATION_LIST}`,data)
export const isReadNotification = (data) => post(`${url.IS_READ_NOTIFICATION}`,data)
export const deleteNotification = (data) => post(`${url.DELETE_NOTIFICATION}`,data)

