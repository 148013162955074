import React, { useEffect, useState, useRef, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import TableContainer from "./allRequestsListContainer"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Spinners from "components/Common/Spinner"
import { getAllServiceRequestList } from "store/actions"

const AllRequestsList = () => {
  const [isLoading, setLoading] = useState(false)
  const navigate = useNavigate()
  document.title = "All Request List"
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))

  const dispatch = useDispatch()
  const serviceRequestState = state =>
    state.serviceRequests.allServiceRequestList.service_requests

  const serviceRequest = useSelector(serviceRequestState) || []

  useEffect(() => {
    if (user) {
      const value = {
        user_id: user.user_id,
      }
      dispatch(getAllServiceRequestList(value))
    }
  }, [dispatch, user])

  const getStatusColor = status => {
    switch (status) {
      case 11:
      case 6:
        return "red"
      case 1:
        return "blue"
      case 2:
        return "green"
      case 3:
        return "orange"
      case 4:
        return "purple"
      case 5:
        return "brown"
      case 7:
        return "cyan"
      case 8:
        return "magenta"
      case 9:
        return "yellow"
      case 10:
        return "pink"
      default:
        return "black"
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "srNo",
        sortBy: true,
        Cell: cellProps => {
          const { id, status } = cellProps.row.original;
          const linkTo =
            status === 1 || status === 2
              ? `/service-requests/view-service-requests/${id}`
              : `/service-requests/reassign-view-service-requests/${id}`;
  
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to={linkTo}>
                  {cellProps.row.index + 1}
                </Link>
              </h5>
            </>
          );
        },
      },
      {
        Header: "SRQ Code",
        accessor: "srq_code",
        filterable: true,
        Cell: cellProps => {
          const { id, status, srq_code } = cellProps.row.original;
          const linkTo =
            status === 1 || status === 2
              ? `/service-requests/view-service-requests/${id}`
              : `/service-requests/reassign-view-service-requests/${id}`;
  
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to={linkTo}>
                  {srq_code}
                </Link>
              </h5>
            </>
          );
        },
      },
      {
        Header: "Request made on",
        accessor: "created_on",
        filterable: true,
        Cell: cellProps => {
          const { id, status, created_on } = cellProps.row.original;
          const linkTo =
            status === 1 || status === 2
              ? `/service-requests/view-service-requests/${id}`
              : `/service-requests/reassign-view-service-requests/${id}`;
  
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to={linkTo}>
                  {created_on}
                </Link>
              </h5>
            </>
          );
        },
      },
      {
        Header: "Project Name",
        accessor: "project_name",
        filterable: true,
        Cell: cellProps => {
          const { id, status, project_name } = cellProps.row.original;
          const linkTo =
            status === 1 || status === 2
              ? `/service-requests/view-service-requests/${id}`
              : `/service-requests/reassign-view-service-requests/${id}`;
  
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to={linkTo}>
                  {project_name}
                </Link>
              </h5>
            </>
          );
        },
      },
      {
        Header: "Customer",
        accessor: "customer_name",
        filterable: true,
        Cell: cellProps => {
          const { id, status, customer_name } = cellProps.row.original;
          const linkTo =
            status === 1 || status === 2
              ? `/service-requests/view-service-requests/${id}`
              : `/service-requests/reassign-view-service-requests/${id}`;
  
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to={linkTo}>
                  {customer_name}
                </Link>
              </h5>
            </>
          );
        },
      },
      {
        Header: "Type of Service",
        accessor: "type_of_service",
        filterable: true,
        Cell: cellProps => {
          const { id, status, service_type } = cellProps.row.original;
          const linkTo =
            status === 1 || status === 2
              ? `/service-requests/view-service-requests/${id}`
              : `/service-requests/reassign-view-service-requests/${id}`;
  
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to={linkTo}>
                  {service_type}
                </Link>
              </h5>
            </>
          );
        },
      },
      {
        Header: "Type of Category",
        accessor: "type_of_category",
        filterable: true,
        Cell: cellProps => {
          const { id, status, type_of_category } = cellProps.row.original;
          const linkTo =
            status === 1 || status === 2
              ? `/service-requests/view-service-requests/${id}`
              : `/service-requests/reassign-view-service-requests/${id}`;
  
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to={linkTo}>
                  {type_of_category}
                </Link>
              </h5>
            </>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status_name",
        filterable: true,
        Cell: cellProps => {
          const { id, status_name, status } = cellProps.row.original;
          const linkTo =
            status === 1 || status === 2
              ? `/service-requests/view-service-requests/${id}`
              : `/service-requests/reassign-view-service-requests/${id}`;
  
          const statusColor = getStatusColor(status);
  
          return (
            <div className="d-flex gap-3">
              <Link
                to={linkTo}
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <span style={{ color: statusColor }}>{status_name}</span>
                <i
                  className="mdi mdi-chevron-right"
                  id="viewtooltip"
                  style={{ fontSize: "18px", color: "#3366cc" }}
                />
                <UncontrolledTooltip placement="top" target="viewtooltip">
                  View
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );
  

  const handleUserClick = arg => {
    const user = arg
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const onClickDelete = users => {
    setDeleteId({ id: users })
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    if (deleteId && deleteId.id) {
      dispatch(deletePendingBills(deleteId.id))
    }
    onPaginationPageChange(1)
    setDeleteModal(false)
    navigate("/pendingBills")
    dispatch(getPendingBillsList())
  }

  const handleUserClicks = () => {
    setUserList("")
    setIsEdit(false)
    toggle()
  }

  const keyField = "id"

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Service Request" breadcrumbItem="Requests" />

          <Row>
            {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : (
              <Col lg="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      isPagination={true}
                      columns={columns}
                      data={serviceRequest}
                      isGlobalFilter={true}
                      isShowingPageLength={true}
                      isAddUserList={true}
                      iscustomPageSizeOptions={true}
                      handleUserClick={handleUserClicks}
                      customPageSize={10}
                      tableClass="table align-middle table-nowrap table-hover"
                      theadClass="table-light"
                      paginationDiv="col-sm-12 col-md-7"
                      pagination="pagination pagination-rounded justify-content-end mt-4"
                    />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AllRequestsList
