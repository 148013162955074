import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { getAllVendorAgingReportList } from "helpers/fakebackend_helper"

const VendorAgingReport = () => {


  const [vendorAgingReportList, setvendorAgingReportList] = useState([])

  // Fetch the customer list when component mounts
  useEffect(() => {
    const fetchvendorAgingReportList = async () => {
      try {
        const response = await getAllVendorAgingReportList()
        setvendorAgingReportList(response.data) // Assuming the response data contains the customer list
      } catch (error) {
        console.error("Error fetching customer list", error)
      }
    }

    fetchvendorAgingReportList()
  }, [])
  

  const projects = [
    { id: "1", project_name: "Project A" },
    { id: "2", project_name: "Project B" },
    { id: "3", project_name: "Project C" },
    { id: "4", project_name: "Project D" },
    { id: "5", project_name: "Project E" },
  ]

  // Sample data for each overdue category (In a real scenario, this data would come from an API or a backend service)
  const overdueData = {
    "1-15 Days Due": [
      { invoiceNo: 1, invoiceDate: "Client A", invoiceAmount: "$150", dueDate: "DD/MM/YYYY", paymentAmount: "$150", pendingAmount: "$150" },
      { invoiceNo: 2, invoiceDate: "Client B", invoiceAmount: "$200", dueDate: "DD/MM/YYYY", paymentAmount: "$150", pendingAmount: "$150"  },
    ],
    "16-30 Days Due": [
      { invoiceNo: 3, invoiceDate: "Client C", invoiceAmount: "$300", dueDate: "DD/MM/YYYY", paymentAmount: "$150", pendingAmount: "$150"  },
      { invoiceNo: 4, invoiceDate: "Client D", invoiceAmount: "$400", dueDate: "DD/MM/YYYY", paymentAmount: "$150", pendingAmount: "$150"  },
    ],
    "31-60 Days Due": [
      { invoiceNo: 5, invoiceDate: "Client E", invoiceAmount: "$500", dueDate: "DD/MM/YYYY", paymentAmount: "$150", pendingAmount: "$150"  },
      { invoiceNo: 6, invoiceDate: "Client F", invoiceAmount: "$600", dueDate: "DD/MM/YYYY", paymentAmount: "$150", pendingAmount: "$150"  },
    ],
    "61-90 Days Due": [
      { invoiceNo: 7, invoiceDate: "Client G", invoiceAmount: "$700", dueDate: "DD/MM/YYYY", paymentAmount: "$150", pendingAmount: "$150"  },
      { invoiceNo: 8, invoiceDate: "Client H", invoiceAmount: "$800", dueDate: "DD/MM/YYYY", paymentAmount: "$150", pendingAmount: "$150"  },
    ],
    "91+ Days Due": [
      { invoiceNo: 9, invoiceDate: "Client I", invoiceAmount: "$900", dueDate: "DD/MM/YYYY", paymentAmount: "$150", pendingAmount: "$150"  },
      { invoiceNo: 10, invoiceDate: "Client J", invoiceAmount: "$1000", dueDate: "DD/MM/YYYY", paymentAmount: "$150", pendingAmount: "$150"  },
    ],
  }

  const [modalOpen, setModalOpen] = useState(false) // Modal visibility state
  const [selectedColumn, setSelectedColumn] = useState("")

  const toggleModal = column => {
    setSelectedColumn(column) // Store which column was clicked
    setModalOpen(!modalOpen) // Toggle modal visibility
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Reports" breadcrumbItem="Vendor Aging Report" />



          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-4">
                    Vendor Aging Report
                  </CardTitle>
                  <Col xxl={2} lg={2}>
                    <select
                      className="form-control select2 mb-3 mb-xxl-0"
                      defaultValue=""
                      onChange={e =>
                        console.log("Selected Project:", e.target.value)
                      }
                    >
                      <option value="">Select Project</option>
                      {projects.map(item => (
                        <option key={item.id} value={item.id}>
                          {item.project_name}
                        </option>
                      ))}
                    </select>
                  </Col>

                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead>
                        <tr>
                          <th>Client ID</th>
                          <th>Client Name</th>
                          <th>Total Outstanding Amount</th>
                          <th
                          >
                            1-15 Days Due
                          </th>
                          <th
                          >
                            16-30 Days Over Due
                          </th>
                          <th
                          >
                            31-60 Days Over Due
                          </th>
                          <th
                          >
                            61-90 Days Over Due
                          </th>
                          <th
                          >
                            91+ Days Over Due
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">1</th>
                          <td>Client A</td>
                          <td>$1000</td>
                          <td className="clickable-column" onClick={() => toggleModal("1-15 Days Due")}>150</td>
                          <td className="clickable-column" onClick={() => toggleModal("16-30 Days Due")}>200</td>
                          <td className="clickable-column" onClick={() => toggleModal("31-60 Days Due")}>300</td>
                          <td className="clickable-column" onClick={() => toggleModal("61-90 Days Due")}>50</td>
                          <td className="clickable-column" onClick={() => toggleModal("91+ Days Due")}>100</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>

            {/* Modal for overdue categories */}
            <Modal isOpen={modalOpen} toggle={() => setModalOpen(false)} className="modal-lg modal-dialog-centered">
              <ModalHeader toggle={() => setModalOpen(false)}>
                {selectedColumn} Details
              </ModalHeader>
              <ModalBody>
                {/* Table displaying the overdue data based on selected column */}
                <Table className="table">
                  <thead>
                    <tr>
                      <th>Invoice No.</th>
                      <th>Invoice Date</th>
                      <th>Invoice Amount</th>
                      <th>Due Date</th>
                      <th>Payment Amount</th>
                      <th>Pending Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Map over the selected column's data */}
                    {(overdueData[selectedColumn] || []).map(item => (
                      <tr key={item.invoiceNo}>
                        <td>{item.invoiceNo}</td>
                        <td>{item.invoiceDate}</td>
                        <td>{item.invoiceAmount}</td>
                        <td>{item.dueDate}</td>
                        <td>{item.paymentAmount}</td>
                        <td>{item.pendingAmount}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={() => setModalOpen(false)}>
                  Close
                </Button>
              </ModalFooter>
            </Modal>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default VendorAgingReport
