import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
  Table,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  UncontrolledTooltip,
} from "reactstrap"

// import { recentfile } from "../../../common/data";
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import {
  deleteAssignedProjectUnit,
  getAssignedProjectUnitList,
} from "store/actions"
import DeleteModal from "components/Common/DeleteModal"

const AssignedProject = () => {
  const [clientId, setClientId] = useState()

  const dispatch = useDispatch()
  const assignedProjectState = state => state.clients.viewAssignedProjectUnit
  const data = useSelector(assignedProjectState)

  const fetchData = () => {
    const url = new URL(window.location.href)
    const clientId = url.pathname.split("/view-client/")[1]
    setClientId(clientId)
    dispatch(getAssignedProjectUnitList(clientId))
  }
  useEffect(() => {
    fetchData()
  }, [dispatch])

  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const onClickDelete = users => {
    setDeleteId({ id: users })
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    if (deleteId && deleteId.id) {
      dispatch(deleteAssignedProjectUnit(deleteId.id))
    }
    setDeleteModal(false)
    dispatch(getAssignedProjectUnitList(clientId))
  }

  const handleUserClicks = () => {
    setUserList("")
    setIsEdit(false)
    toggle()
  }
  const [permission, setPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))
  )

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
       <div className="mt-1 text-end">
        {permission
          .filter(
            perm =>
              perm.page === "/view-client/add-new-assigned-project-unit/:id" &&
              perm.access === 1
          )
          .map(index => (
            <Link
              key={index}
              to={`/clients/view-client/add-new-assigned-project-unit/${clientId}`}
            >
              <button type="submit" className="btn btn-primary w-md">
                Add Assign Project Unit
              </button>
            </Link>
          ))}
      </div>
      <div className="table-responsive">
        <Table className="table align-middle table-nowrap table-hover mb-0">
          <thead>
            <tr>
              <th scope="col">Project</th>
              <th scope="col">Project Unit</th>
              <th scope="col">Brand Name</th>
              <th scope="col">Sale/Rent Amount</th>
              <th scope="col">Agreement Start Date</th>
              <th scope="col">Agreement End Date</th>
              <th scope="col">Status</th>
              <th scope="col">Document</th>
              <th scope="col" colSpan={2}>
                Action
              </th>
              {/* <th scope="col">Status</th> */}
            </tr>
          </thead>
          {Array.isArray(data?.project_units) &&
          data?.project_units.length > 0 ? (
            <tbody>
              {data?.project_units?.map((item, key) => (
                <tr key={key}>
                  <td>
                    <Link to="#" className="text-dark fw-medium">
                      {item.project_name}
                    </Link>
                  </td>
                  <td>{item.unit_code}</td>
                  <td>{item.brand_name}</td>
                  <td>{item.if_sale === 1 ?  item.sale_amount : item.if_sale === 0 ? item.sale_amount : "NA"}</td>
                  <td>{item.agreement_start_date_formatted}</td>
                  <td>{item.agreement_end_date_formatted}</td>
                  <td
                    className={
                      item.status === "expired" ? "text-danger" : "text-primary"
                    }
                  >
                    {item.status === "active" ? "Active" : "Expired"}
                  </td>
                  <td>
                    <div className="d-flex gap-3">
                      <td>
                        {item && item.download_document && (
                          <a
                            href={item.download_document}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-success"
                            download
                          >
                            <i
                              className="mdi mdi-download font-size-18"
                              id={`downloadleaseagreementtooltip`}
                              style={{ color: "#3366cc" }}
                            />
                            <UncontrolledTooltip
                              placement="top"
                              target={`downloadleaseagreementtooltip`}
                            >
                              Download
                            </UncontrolledTooltip>
                          </a>
                        )}
                      </td>

                      <td>
                        {item && item.download_document && (
                          <a
                            href={item.document}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-success"
                          >
                            <i
                              className="mdi mdi-eye font-size-18"
                              id={`viewleaseagreementtooltip-${key}`}
                              style={{ color: "#3366cc" }}
                            />
                            <UncontrolledTooltip
                              placement="top"
                              target={`viewleaseagreementtooltip-${key}`}
                            >
                              View Lease Agreement Document
                            </UncontrolledTooltip>
                          </a>
                        )}
                      </td>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex gap-3">
                      {permission
                        .filter(
                          perm =>
                            perm.page ===
                              "/view-client/edit-new-assigned-project-unit/:id" &&
                            perm.access === 1
                        )
                        .map(
                          index =>
                            item &&
                            item?.status === "active" && (
                              <Link
                                key={index}
                                to={`/clients/view-client/edit-new-assigned-project-unit/${item.id}`}
                                className="text-success"
                              >
                                <i
                                  className="mdi mdi-pencil font-size-18"
                                  id="edittooltip"
                                  style={{ color: "#3366cc" }}
                                />
                                <UncontrolledTooltip
                                  placement="top"
                                  target="edittooltip"
                                >
                                  Edit
                                </UncontrolledTooltip>
                              </Link>
                            )
                        )}
                      {permission
                        .filter(
                          perm =>
                            perm.page === "delete-assigned-project" &&
                            perm.access === 1
                        )
                        .map(
                          index =>
                            item &&
                            item?.status === "active" && (
                              <Link
                                key={index}
                                to="#"
                                className="text-danger"
                                onClick={() => {
                                  // const userData = cellProps.row.original.id
                                  onClickDelete(item.id)
                                }}
                              >
                                <i
                                  className="mdi mdi-delete font-size-18"
                                  id="deletetooltip"
                                  style={{ color: "#3366cc" }}
                                />
                                <UncontrolledTooltip
                                  placement="top"
                                  target="deletetooltip"
                                >
                                  Delete
                                </UncontrolledTooltip>
                              </Link>
                            )
                        )}
                      {permission
                        .filter(
                          perm =>
                            perm.page ===
                              "/view-client/view-assigned-project-unit/:id" &&
                            perm.access === 1
                        )
                        .map(index => (
                          <Link
                            key={index}
                            to={`/clients/view-client/view-assigned-project-unit/${item.id}`}
                            className="text-success"
                          >
                            <i
                              className="mdi mdi-chevron-right font-size-18"
                              id={`viewtooltip-${key}`}
                              style={{ color: "#3366cc" }}
                            />
                            <UncontrolledTooltip
                              placement="top"
                              target={`viewtooltip-${key}`}
                            >
                              View
                            </UncontrolledTooltip>
                          </Link>
                        ))}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan="5" className="text-center">
                  No Records Found
                </td>
              </tr>
            </tbody>
          )}
        </Table>
      </div>
     

      {/* </div> */}
    </React.Fragment>
  )
}

export default AssignedProject
