import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"

// Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  getAllCustomerAgingReportList,
  getAllProjects,
  getCustomerAgingReport,
} from "helpers/fakebackend_helper"

const CustomerAgingReport = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))
  const [customerAgingReportList, setCustomerAgingReportList] = useState([])
  const [projectId, setProjectId] = useState("")
  const [projects, setProjectList] = useState([])
  const [agingListForModel, setAgingListForModel] = useState([])

  const [modalOpen, setModalOpen] = useState(false) // Modal visibility state
  const [selectedColumn, setSelectedColumn] = useState("")

  useEffect(() => {
    if (!modalOpen) {
      setSelectedColumn("")
    }
  }, [modalOpen])
  const toggleModal = column => {
    setSelectedColumn(column) // Store which column was clicked
    setModalOpen(!modalOpen) // Toggle modal visibility
  }

  // Fetch the customer aging report list when projectId changes
  useEffect(() => {
    const fetchCustomerAgingReportList = async () => {
      try {
        const value = {
          project_id: projectId,
        }
        const response = await getAllCustomerAgingReportList(value)
        setCustomerAgingReportList(response.data)
      } catch (error) {
        console.error("Error fetching customer aging report list", error)
      }
    }

    if (projectId) {
      fetchCustomerAgingReportList()
    }
  }, [projectId])

  // Fetch all projects when user is available
  useEffect(() => {
    const fetchProjectList = async () => {
      try {
        const response = await getAllProjects(user.user_id)
        setProjectList(response.project)
      } catch (error) {
        console.error("Error fetching project list", error)
      }
    }

    if (user) {
      fetchProjectList()
    }
  }, [user])

  useEffect(() => {
    const fetchModalData = async () => {
      try {
        const value = {
          project_id: projectId,
          days: selectedColumn,
        }

        const response = await getCustomerAgingReport(value)
        setAgingListForModel(response.data)
      } catch (error) {
        console.error("Error fetching project list", error)
      }
    }

    if (selectedColumn) {
      fetchModalData()
    }
  }, [selectedColumn])

  

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Reports" breadcrumbItem="Customer Aging Report" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-4">
                    Customer Aging Report
                  </CardTitle>
                  <Col xxl={2} lg={2}>
                    <select
                      className="form-control select2 mb-3 mb-xxl-0"
                      value={projectId}
                      onChange={e => setProjectId(e.target.value)}
                    >
                      <option value="">Select Project</option>
                      {projects.map(item => (
                        <option key={item.id} value={item.id}>
                          {item.project_name}
                        </option>
                      ))}
                    </select>
                  </Col>

                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead>
                        <tr>
                          <th>Customer Code</th>
                          <th>Customer Name</th>
                          <th>Total Outstanding Amount</th>
                          <th>1-15 Days Due</th>
                          <th>16-30 Days Over Due</th>
                          <th>31-60 Days Over Due</th>
                          <th>61-90 Days Over Due</th>
                          <th>91+ Days Over Due</th>
                        </tr>
                      </thead>
                      <tbody>
                        {customerAgingReportList.map((report, index) => (
                          <tr key={index}>
                            <td>{report.customer_code}</td>
                            <td>{report.customer_name}</td>
                            <td className="clickable-column"
                              onClick={() => toggleModal("")}>{report.total_outstanding_amount}</td>
                            <td
                              className="clickable-column"
                              onClick={() => toggleModal("1-15")}
                            >
                              {report.one_to_fifteen_days}
                            </td>
                            <td
                              className="clickable-column"
                              onClick={() => toggleModal("16-30")}
                            >
                              {report.sixteen_to_thirty_days}
                            </td>
                            <td
                              className="clickable-column"
                              onClick={() => toggleModal("31-60")}
                            >
                              {report.thirtyone_to_sixty_days}
                            </td>
                            <td
                              className="clickable-column"
                              onClick={() => toggleModal("61-90")}
                            >
                              {report.sixtyone_to_ninety_days}
                            </td>
                            <td
                              className="clickable-column"
                              onClick={() => toggleModal("91+")}
                            >
                              {report.more_than_ninety_days}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>

            {/* Modal for overdue categories */}
            <Modal
              isOpen={modalOpen}
              toggle={() => setModalOpen(false)}
              className="modal-lg modal-dialog-centered"
            >
              <ModalHeader toggle={() => setModalOpen(false)}>
                {selectedColumn} Details
              </ModalHeader>
              <ModalBody>
                {/* Table displaying the overdue data based on selected column */}
                <Table className="table">
                  <thead>
                    <tr>
                      <th>Invoice No.</th>
                      <th>Invoice Date</th>
                      <th>Invoice Amount</th>
                      <th>Due Date</th>
                      <th>Payment Amount</th>
                      <th>Pending Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(agingListForModel || []).map((agingModelData, index) => (
                      <tr key={index}>
                        <td>{agingModelData.customer_code}</td>
                        <td>{agingModelData.customer_name}</td>
                        <td>{agingModelData.total_outstanding_amount}</td>
                        <td>{agingModelData.one_to_fifteen_days}</td>
                        <td>{agingModelData.sixteen_to_thirty_days}</td>
                        <td>{agingModelData.thirtyone_to_sixty_days}</td>
                        <td>{agingModelData.sixtyone_to_ninety_days}</td>
                        <td>{agingModelData.more_than_ninety_days}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={() => setModalOpen(false)}>
                  Close
                </Button>
              </ModalFooter>
            </Modal>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CustomerAgingReport
