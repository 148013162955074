import React, { useEffect, useRef, useCallback, useState } from "react"
import { useLocation } from "react-router-dom"
import PropTypes from "prop-types"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import withRouter from "components/Common/withRouter"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const [permission, setPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))
  )

  const ref = useRef()
  const activateParentDropdown = useCallback(item => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }, [])

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement

      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show")
        }

        parent.classList.remove("mm-active")
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.remove("mm-show")

          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.remove("mm-active") // li
            parent3.childNodes[0].classList.remove("mm-active")

            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.remove("mm-show") // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.remove("mm-show") // li
                parent5.childNodes[0].classList.remove("mm-active") // a tag
              }
            }
          }
        }
      }
    }
  }

  const path = useLocation()
  const activeMenu = useCallback(() => {
    const pathName = path.pathname
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    removeActivation(items)

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [path.pathname, activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  }, [])

  useEffect(() => {
    new MetisMenu("#side-menu")
    activeMenu()
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    activeMenu()
  }, [activeMenu])

  useEffect(() => {
    const storedPermission = JSON.parse(localStorage.getItem("permission"))
    setPermission(storedPermission)
  }, [localStorage.getItem("permission")])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            {permission
              .filter(item => item.page === "dashboard" && item.access === 1)
              .map((item, index) => (
                <li key={index}>
                  <Link to="/dashboard">
                    <i className="mdi mdi-home-outline"></i>
                    <span>{props.t("Dashboard")}</span>
                  </Link>
                </li>
              ))}
            {/* {permission.some(
              item =>
                item.page === "/rfq-list" &&
                item.access === 1
            ) && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-dish"></i>
                  <span>{props.t("Project Management")}</span>
                </Link>
                <ul className="sub-menu">
                  {permission
                    .filter(
                      item => item.page === "/service-request-dashboard" && item.display_sequence===188 && item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        <Link to="/service-requests/service-request-dashboard">
                          {props.t("Dashboard")}
                        </Link>
                      </li>
                    ))}
                  {permission
                    .filter(
                      item =>
                        item.page === "/rfq-list" &&
                        item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        <Link to="/project-management/rfq-list">
                          {props.t("All RFQ")}
                        </Link>
                      </li>
                    ))}
                </ul>
              </li>
            )} */}
            {permission.some(
              item =>
                (item.page === "/all-service-requests" ||
                  item.page === "/service-request-dashboard") &&
                item.access === 1
            ) && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-dish"></i>
                  <span>{props.t("Service Request")}</span>
                </Link>
                <ul className="sub-menu">
                  {permission
                    .filter(
                      item =>
                        item.page === "/service-request-dashboard" &&
                        item.display_sequence === 188 &&
                        item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        <Link to="/service-requests/service-request-dashboard">
                          {props.t("Dashboard")}
                        </Link>
                      </li>
                    ))}
                  {permission
                    .filter(
                      item =>
                        item.page === "/all-service-requests" &&
                        item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        <Link to="/service-requests/all-service-requests">
                          {props.t("Requests")}
                        </Link>
                      </li>
                    ))}
                </ul>
              </li>
            )}
            {permission.some(
              item =>
                (item.page === "/tickets-list" ||
                  item.page === "/ticket-dashboard") &&
                item.access === 1
            ) && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="mdi mdi-ticket-outline"></i>
                  <span>{props.t("Tickets")}</span>
                </Link>
                <ul className="sub-menu">
                  {permission
                    .filter(
                      item =>
                        item.page === "/ticket-dashboard" &&
                        item.display_sequence === 189 &&
                        item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        <Link to="/tickets/ticket-dashboard">
                          {props.t("Dashboard")}
                        </Link>
                      </li>
                    ))}
                  {permission
                    .filter(
                      item => item.page === "/tickets-list" && item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        <Link to="/tickets/tickets-list">
                          {props.t("All Tickets")}
                        </Link>
                      </li>
                    ))}
                </ul>
              </li>
            )}

            {permission.some(
              item =>
                (item.page === "clients" ||
                  item.page === "/add-new-client" ||
                  item.page === "/service-engagement/all-engagement-list" ||
                  // item.page === "/service-engagement/approval-pending-list" ||
                  item.page ===
                    "/service-engagement/billing-setup-pending-list") &&
                item.access === 1
            ) && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="mdi mdi-account-tie"></i>
                  <span>{props.t("Clients")}</span>
                </Link>
                <ul className="sub-menu">
                  {permission
                    .filter(
                      item => item.page === "clients" && item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        <Link to="/clients">{props.t("All Clients")}</Link>
                      </li>
                    ))}

                  {permission
                    .filter(
                      item =>
                        item.page === "/add-new-client" && item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        <Link to="/clients/add-new-client">
                          {props.t("Add New Client")}
                        </Link>
                      </li>
                    ))}
                  {permission
                    .filter(
                      item =>
                        item.page === "/add-new-client" && item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        <Link to="/clients/customer-index">
                          {props.t("Customer Index")}
                        </Link>
                      </li>
                    ))}
                  {permission.some(
                    item =>
                      (item.page ===
                        "/service-engagement/all-engagement-list" ||
                        item.page ===
                          "/service-engagement/approval-pending-list" ||
                        item.page ===
                          "/service-engagement/billing-setup-pending-list") &&
                      item.access === 1
                  ) && (
                    <li>
                      <Link to="/" className="has-arrow">
                        {props.t("Service Engagement(s)")}
                      </Link>
                      <ul className="sub-menu ">
                        {permission
                          .filter(
                            item =>
                              item.page ===
                                "/service-engagement/all-engagement-list" &&
                              item.access === 1
                          )
                          .map((item, index) => (
                            <li key={index}>
                              {" "}
                              <Link to="/clients/service-engagement/all-engagement-list">
                                {props.t("All engagement")}
                              </Link>
                            </li>
                          ))}

                        {permission
                          .filter(
                            item =>
                              item.page ===
                                "/service-engagement/billing-setup-pending-list" &&
                              item.access === 1
                          )
                          .map((item, index) => (
                            <li key={index}>
                              {" "}
                              <Link to="/clients/service-engagement/billing-setup-pending-list">
                                {props.t("Billing setup pending")}
                              </Link>
                            </li>
                          ))}
                      </ul>
                    </li>
                  )}
                </ul>
              </li>
            )}
            {permission.some(
              item =>
                (item.page === "vendors" ||
                  item.page === "add-new-vendor" ||
                  item.page === "add-vendor-payment" ||
                  item.page === "vendor-bill-history" ||
                  item.page === "vendor-proposal" ||
                  item.page === "/debit-note-list" ||
                  item.page === "/add-debit-note" ||
                  item.page === "vendor-payment-history") &&
                item.access === 1
            ) && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="mdi mdi-handshake-outline"></i>
                  <span>{props.t("Vendor")}</span>
                </Link>
                <ul className="sub-menu">
                  {permission
                    .filter(
                      item => item.page === "vendors" && item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        <Link to="/vendors">{props.t("All Vendor")}</Link>
                      </li>
                    ))}
                  {permission
                    .filter(
                      item =>
                        item.page === "add-new-vendor" && item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        <Link to="/add-new-vendor">
                          {props.t("Add New Vendor")}
                        </Link>
                      </li>
                    ))}
                  {permission
                    .filter(
                      item =>
                        item.page === "vendor-bill-history" && item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        <Link to="/vendor-bill-history">
                          {props.t("Bill History")}
                        </Link>
                      </li>
                    ))}

                  {permission
                    .filter(
                      item =>
                        item.page === "add-vendor-payment" && item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        <Link to="/add-vendor-payment">
                          {props.t("Add Payment")}
                        </Link>
                      </li>
                    ))}
                  {permission
                    .filter(
                      item =>
                        item.page === "vendor-payment-history" &&
                        item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        <Link to="/vendor-payment-history">
                          {props.t("Bill Payment")}
                        </Link>
                      </li>
                    ))}
                  {permission
                    .filter(
                      item =>
                        item.page === "/add-debit-note" && item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        {" "}
                        <Link to="/vendor/add-debit-note">
                          {props.t("Create Debit Note")}
                        </Link>
                      </li>
                    ))}
                  {permission
                    .filter(
                      item =>
                        item.page === "/debit-note-list" && item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        {" "}
                        <Link to="/vendor/debit-note-list">
                          {props.t("Debit Note List")}
                        </Link>
                      </li>
                    ))}
                  {permission
                    .filter(
                      item =>
                        item.page === "vendor-proposal" && item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        <Link to="/vendor-proposal">
                          {props.t("Vendor Proposals")}
                        </Link>
                      </li>
                    ))}
                </ul>
              </li>
            )}
            {permission.some(
              item =>
                (item.page === "projects" ||
                  item.page === "add-new-project" ||
                  item.page === "add-new-project-unit") &&
                item.access === 1
            ) && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-task"></i>
                  <span>{props.t("Projects")}</span>
                </Link>
                <ul className="sub-menu">
                  {permission
                    .filter(
                      item => item.page === "projects" && item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        <Link to="/projects">{props.t("All Projects")}</Link>
                      </li>
                    ))}
                  {permission
                    .filter(
                      item =>
                        item.page === "add-new-project" && item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        <Link to="/add-new-project">
                          {props.t("Add New projects")}
                        </Link>
                      </li>
                    ))}
                  {permission
                    .filter(
                      item =>
                        item.page === "add-new-project-unit" &&
                        item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        <Link to="/add-new-project-unit">
                          {props.t("Add New project unit")}
                        </Link>
                      </li>
                    ))}
                  {/* <li>
                  <Link to="/add-project-occupancy">
                    {props.t("Project occupancy report")}
                  </Link>
                </li> */}
                </ul>
              </li>
            )}
            {permission.some(
              item =>
                (item.page === "users" || item.page === "/add-new-user") &&
                item.access === 1
            ) && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className=" mdi mdi-account-outline"></i>
                  <span>{props.t("Users")}</span>
                </Link>
                <ul className="sub-menu">
                  {permission
                    .filter(item => item.page === "users" && item.access === 1)
                    .map((item, index) => (
                      <li key={index}>
                        <Link to="/users">{props.t("All Users")}</Link>
                      </li>
                    ))}
                  {permission
                    .filter(
                      item => item.page === "/add-new-user" && item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        <Link to="/users/add-new-user">
                          {props.t("Add New User")}
                        </Link>
                      </li>
                    ))}
                </ul>
              </li>
            )}
            {permission.some(
              item =>
                (item.page === "/billing-register" ||
                  item.page === "/create-new-bill" ||
                  item.page === "/add-receipt" ||
                  // item.page === "/recurring-bills" ||
                  item.page === "/receipt") &&
                item.access === 1
            ) && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="mdi mdi-file-document-edit-outline"></i>
                  <span>{props.t("Billing ")}</span>
                </Link>
                <ul className="sub-menu">
                  {permission
                    .filter(
                      item =>
                        item.page === "/billing-register" && item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        {" "}
                        <Link to="/billing/billing-register">
                          {props.t("Billing Register")}
                        </Link>
                      </li>
                    ))}
                  {permission
                    .filter(
                      item =>
                        item.page === "/create-new-bill" && item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        {" "}
                        <Link to="/billing/create-new-bill">
                          {props.t("Create New Bill")}
                        </Link>
                      </li>
                    ))}

                  {permission
                    .filter(
                      item => item.page === "/receipt" && item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        {" "}
                        <Link to="/billing/receipt">{props.t("Receipts")}</Link>
                      </li>
                    ))}
                  {permission
                    .filter(
                      item => item.page === "/add-receipt" && item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        {" "}
                        <Link to="/billing/add-receipt">
                          {props.t("Add Receipt")}
                        </Link>
                      </li>
                    ))}
                  {permission
                    .filter(
                      item =>
                        item.page === "/credit-note-list" && item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        {" "}
                        <Link to="/billing/credit-note-list">
                          {props.t("Credit Note List")}
                        </Link>
                      </li>
                    ))}
                  {permission
                    .filter(
                      item =>
                        item.page === "/add-credit-note" && item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        {" "}
                        <Link to="/billing/add-credit-note">
                          {props.t("Create Credit Note")}
                        </Link>
                      </li>
                    ))}
                </ul>
              </li>
            )}
            {/* {permission.some(
              item => item.page === "renewals" && item.access === 1
            ) && (
              <li>
                <Link to="/renewals">
                  <i className="mdi mdi-notebook-edit-outline"></i>
                  <span>{props.t("Renewals")}</span>
                </Link>
              </li>
            )} */}
            {permission.some(
              item =>
                (item.page === "renewals" ||
                  item.page === "/project-compliances" ||
                  item.page === "/vendor-contract-renewal") &&
                item.access === 1
            ) && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="mdi mdi-notebook-edit-outline"></i>
                  <span>{props.t("Renewals")}</span>
                </Link>
                <ul className="sub-menu">
                  {permission
                    .filter(
                      item => item.page === "renewals" && item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        <Link to="/renewals">
                          {props.t("Customer Renewals")}
                        </Link>
                      </li>
                    ))}
                  {permission
                    .filter(
                      item =>
                        item.page === "/project-compliances" &&
                        item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        <Link to="/renewals/project-compliances">
                          {props.t("Project Compliance Certificate")}
                        </Link>
                      </li>
                    ))}
                  {permission
                    .filter(
                      item =>
                        item.page === "/vendor-contract-renewal" &&
                        item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        <Link to="/renewals/vendor-contract-renewal">
                          {props.t("Vendor Contract Renewal")}
                        </Link>
                      </li>
                    ))}
                </ul>
              </li>
            )}
            {permission.some(
              item =>
                (item.page === "/consumption-report" ||
                  item.page === "/add-new-consumption") &&
                item.access === 1
            ) && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bxs-bar-chart-alt-2"></i>
                  <span>{props.t("Consumption")}</span>
                </Link>
                <ul className="sub-menu">
                  {permission
                    .filter(
                      item =>
                        item.page === "/consumption-report" && item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        <Link to="/consumption/consumption-report">
                          {props.t("Consumption Report")}
                        </Link>
                      </li>
                    ))}
                  {permission
                    .filter(
                      item =>
                        item.page === "/add-new-consumption" &&
                        item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        <Link to="/consumption/add-new-consumption">
                          {props.t("Add New Consumption")}
                        </Link>
                      </li>
                    ))}
                </ul>
              </li>
            )}
            {permission.some(
              item =>
                (item.page === "/project-occupancy-report" ||
                  item.page === "/gst-report" ||
                  item.page === "/customer-oustanding-report") &&
                item.access === 1
            ) && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bxs-report"></i>
                  <span>{props.t("Reports ")}</span>
                </Link>
                <ul className="sub-menu">
                  {permission
                    .filter(
                      item =>
                        item.page === "/project-occupancy-report" &&
                        item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        {" "}
                        <Link to="/reports/project-occupancy-report">
                          {props.t("Project Occupancy Report")}
                        </Link>
                      </li>
                    ))}
                  {permission
                    .filter(
                      item => item.page === "/gst-report" && item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        {" "}
                        <Link to="/reports/gst-report">
                          {props.t("GST Report")}
                        </Link>
                      </li>
                    ))}
                  {permission
                    .filter(
                      item =>
                        item.page === "/customer-oustanding-report" &&
                        item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        {" "}
                        <Link to="/reports/customer-oustanding-report?customer=true">
                          {props.t("Customer Outstanding Report")}
                        </Link>
                      </li>
                    ))}
                  {permission
                    .filter(
                      item =>
                        item.page === "/vendor-summary-report" &&
                        item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        {" "}
                        <Link to="/reports/vendor-summary-report">
                          {props.t("Vendor Summary Report")}
                        </Link>
                      </li>
                    ))}
                  {permission
                    .filter(
                      item =>
                        item.page === "/customer-aging-report" &&
                        item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        {" "}
                        <Link to="/reports/customer-aging-report">
                          {props.t("Customer Aging Report")}
                        </Link>
                      </li>
                    ))}
                  {permission
                    .filter(
                      item =>
                        item.page === "/vendor-aging-report" &&
                        item.access === 1
                    )
                    .map((item, index) => (
                      <li key={index}>
                        {" "}
                        <Link to="/reports/vendor-aging-report">
                          {props.t("Vendor Aging Report")}
                        </Link>
                      </li>
                    ))}
                </ul>
              </li>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
