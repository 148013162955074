import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import axios from "axios";
import DeleteModal from "components/Common/DeleteModal";
import { deleteVendorsContract, getVendorContractList } from "helpers/fakebackend_helper";

const VendorContracts = () => {
  const [vendorId, setVendorId] = useState();
  const [vendorContacts, setVendorContacts] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [permission, setPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))
  );

  // Fetch vendor contacts
  const fetchVendorContacts = async (vendorId) => {
    try {
      const response = await getVendorContractList(vendorId);
      setVendorContacts(response.data || []);
    } catch (error) {
      console.error("Error fetching vendor contacts:", error);
    }
  };

  const fetchData = () => {
    const url = new URL(window.location.href);
    const vendorId = url.pathname.split("/view-vendor/")[1];
    setVendorId(vendorId);
    if (vendorId) {
      fetchVendorContacts(vendorId);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onClickDelete = (contractId) => {
    setDeleteId(contractId);
    setDeleteModal(true);
  };

  // Delete vendor contact
  const handleDeleteUser = async () => {
    try {
      await deleteVendorsContract(deleteId);
      setDeleteModal(false);
      fetchData() 
    } catch (error) {
      console.error("Error deleting vendor contact:", error);
    }
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
     <div className="table-responsive"> 
  <Table className="table align-middle table-nowrap table-hover mb-0">
    <thead>
      <tr>
        <th scope="col">Purpose</th>
        <th scope="col">From</th>
        <th scope="col">To</th>
        <th scope="col" colSpan={2}>Services</th>
      </tr>
    </thead>
    {Array.isArray(vendorContacts) && vendorContacts.length > 0 ? (
      <tbody>
        {vendorContacts.map((item, key) => (
          <tr key={key}>
            <td>
              <Link to="#" className="text-dark fw-medium">
                {item.purpose}
              </Link>
            </td>
            <td>{item.validity_from}</td>
            <td>{item.validity_to}</td>
            <td>
              {/* Mapping services to show comma-separated values */}
              {item.services.map(service => service.service_id).join(", ")}
            </td>
            <td>
              <div className="d-flex gap-3">
                {permission
                  .filter(
                    (perm) =>
                      perm.page === "delete-vendor-contract" && perm.access === 1
                  )
                  .map((_, index) => (
                    <Link
                      key={index}
                      to="#"
                      className="text-danger"
                      onClick={() => onClickDelete(item.id)}
                    >
                      <i
                        className="mdi mdi-delete font-size-18"
                        id="deletetooltip"
                        style={{ color: "#3366cc" }}
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target="deletetooltip"
                      >
                        Delete
                      </UncontrolledTooltip>
                    </Link>
                  ))}
                {permission
                  .filter(
                    (perm) =>
                      perm.page === "/edit-vendor-contract/:id" && perm.access === 1
                  )
                  .map((_, index) => (
                    <Link
                      key={index}
                      to={`/vendor/edit-vendor-contract/${item.id}`}
                      className="text-success"
                    >
                      <i
                        className="mdi mdi-pencil font-size-18"
                        id={`edittooltip-${key}`}
                        style={{ color: "#3366cc" }}
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target={`edittooltip-${key}`}
                      >
                        Edit
                      </UncontrolledTooltip>
                    </Link>
                  ))}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    ) : (
      <tbody>
        <tr>
          <td colSpan="5" className="text-center">
            No Records Found
          </td>
        </tr>
      </tbody>
    )}
  </Table>
</div>

      <div className="mt-3 text-end">
        {permission
          .filter(
            (perm) =>
              perm.page === "/add-vendor-contract/:id" && perm.access === 1
          )
          .map((_, index) => (
            <Link key={index} to={`/vendor/add-vendor-contract/${vendorId}`}>
              <button type="submit" className="btn btn-primary w-md">
                Add Vendor Contract
              </button>
            </Link>
          ))}
      </div>
    </React.Fragment>
  );
};

export default VendorContracts;
